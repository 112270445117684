import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Loader } from "../../../../components/loader/loader.component";
import { useMediaListener } from "../../../../hooks/useMediaListener";
import { selectCertificatesHistory } from "../../redux/idd.selectors";
import { IddActions } from "../../redux/idd.reducer";
import { CertificateItemDesktop } from "../certificateItemDesktop/certificateItemDesktop.component";
import { ListHeader, ListItems } from "../filesToDownload/filesToDownload.styled";
import { ListEmptyState } from "../listEmptyState/listEmptyState.component";

import {
  Container,
  HeaderItem,
  StyledCertificateItemMobile,
  StyledListPagination,
} from "./certificatesHistory.styled";

export const CertificatesHistory = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isTabletWide } = useMediaListener();
  const certificates = useSelector(selectCertificatesHistory);
  const items = certificates?.elements;

  useEffect(() => {
    dispatch(IddActions.getCertificatesHistory());
  }, []);

  const handlePageChange = (page) => {
    dispatch(IddActions.getCertificatesHistory(page));
  };

  if (!items) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  if (items?.length === 0) {
    return (
      <Container>
        <ListEmptyState />
      </Container>
    );
  }

  return (
    <Container>
      {isTabletWide ? (
        <>
          <ListHeader>
            <HeaderItem>{t("idd.certificatesHistory.year")}</HeaderItem>
            <HeaderItem>{t("idd.certificatesHistory.status")}</HeaderItem>
            <HeaderItem>{t("idd.certificatesHistory.endDate")}</HeaderItem>
            <HeaderItem></HeaderItem>
          </ListHeader>
          <ListItems>
            {items?.map(({ id, passed, validityTimestamp }) => (
              <CertificateItemDesktop
                key={id}
                id={id}
                passed={passed}
                validityTimestamp={validityTimestamp}
              />
            ))}
          </ListItems>
        </>
      ) : (
        items?.map(({ id, passed, validityTimestamp }) => (
          <StyledCertificateItemMobile
            key={id}
            id={id}
            passed={passed}
            validityTimestamp={validityTimestamp}
          />
        ))
      )}
      {certificates?.maxPage > 1 && (
        <StyledListPagination
          currentPage={certificates.currentPage}
          pagesTotal={certificates.maxPage}
          onPrevClick={() => handlePageChange(certificates.currentPage - 1)}
          onNextClick={() => handlePageChange(certificates.currentPage + 1)}
          onPageClick={handlePageChange}
        />
      )}
    </Container>
  );
};
