import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { selectUserData } from "../../../../userContext/redux/userContext.selectors";
import { ButtonVariant } from "../../../../components/button/button.constants";
import { simpleDateFromTimestamp } from "../../../../helpers/date";
import { AppConfig } from "../../../../appConfig";
import {
  ListMobileProp,
  ListMobilePropText,
  ListMobilePropTitle,
  ListMobileDownloadButton,
  ListMobileDownloadIcon,
} from "../filesToDownload/filesToDownload.styled";
import { getUriType } from "../passHistory/passHistory.utils";

import {
  Container,
  Header,
  Thumbnail,
  HeaderText,
  Time,
  TimeIcon,
  Title,
} from "./passItemMobile.styled";

export const PassItemMobile = ({
  className,
  id,
  title,
  minutes,
  type,
  imageSrc,
  passTimestamp,
}) => {
  const { t } = useTranslation();
  const user = useSelector(selectUserData);
  const date = simpleDateFromTimestamp(passTimestamp);
  const uriType = getUriType(type.type);

  const handleDownload = () => {
    window.open(`${AppConfig.api}/download/${uriType}/${id}?X-AUTH-TOKEN=${user.token}`);
  };

  return (
    <Container className={className}>
      <Header>
        <Thumbnail src={imageSrc} />
        <HeaderText>
          <Time>
            <TimeIcon />
            {minutes} {t("idd.passItemMobile.minutes")}
          </Time>
          <Title>{title}</Title>
        </HeaderText>
      </Header>
      <ListMobileProp>
        <ListMobilePropTitle>{t("idd.passItemMobile.type")}</ListMobilePropTitle>
        <ListMobilePropText>{type.label}</ListMobilePropText>
      </ListMobileProp>
      <ListMobileProp>
        <ListMobilePropTitle>{t("idd.passItemMobile.passDate")}</ListMobilePropTitle>
        <ListMobilePropText>{date}</ListMobilePropText>
      </ListMobileProp>
      <ListMobileDownloadButton
        variant={ButtonVariant.Outlined}
        onClick={handleDownload}
        disabled={!uriType}
      >
        <ListMobileDownloadIcon />
        {t("idd.passItemMobile.download")}
      </ListMobileDownloadButton>
    </Container>
  );
};
