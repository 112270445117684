import styled from "styled-components";
import { ContentMaxWidth, Media, Separator } from "../../../../theme";

export const Container = styled.div`
  padding: 60px 0;

  ${Media.tablet`
    padding: 80px 0;
  `}
`;

export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: calc(${ContentMaxWidth} + 40px);

  ${Media.tablet`
    padding: 0 20px;
  `}
`;

export const StyledSeparator = styled(Separator)`
  margin-top: 40px;
  margin-bottom: 40px;

  ${Media.tablet`
    margin-top: 48px;
    margin-bottom: 48px;
  `}
`;
