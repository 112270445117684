import styled, { css } from "styled-components";
import { prop } from "ramda";

import { ReactComponent as CheckSvg } from "../../../../images/check2.svg";
import { ReactComponent as CloseSvg } from "../../../../images/close2.svg";
import { Button } from "../../../../components/button/button.component";
import { styleWhenTrue } from "../../../../helpers/rendering";
import { Color, FontWeight, Media } from "../../../../theme";

export const Container = styled.div`
  background-color: ${Color.white};
  padding: 25px;
`;

export const SlidesCounter = styled.p`
  font-size: 14px;
  line-height: 20px;
`;

export const CurrentSlide = styled.span`
  font-weight: ${FontWeight.SemiBold};
`;

export const HeaderText = styled.p`
  font-size: 24px;
  line-height: 32px;
  font-weight: ${FontWeight.Bold};
  margin-top: 20px;
`;

export const Separator = styled.div`
  height: 2px;
  background-color: ${() => Color.brandBasic};
  margin: 15px 0 52px;
`;

export const Question = styled.p`
  font-size: 26px;
  line-height: 35px;
`;

export const HelperText = styled.p`
  margin-top: 10px;
  font-size: 14px;
  line-height: 17px;
  color: ${Color.gray9};
`;

export const Answers = styled.div`
  margin-top: 25px;
`;

export const Answer = styled.button`
  border: 1px solid ${Color.dirtyWhite};
  background-color: ${Color.transparent};
  width: 100%;
  padding: 25px 20px;
  display: flex;
  text-align: left;
  align-items: center;
  
  & + & {
    margin-top: 2px;
  }

  ${styleWhenTrue(prop("isChecked"), css`
    border: 1px solid ${Color.blue5};
    background-color: ${Color.blue6};
  `)}

  ${styleWhenTrue(prop("isCorrect"), css`
    border: 1px solid ${Color.green};
    background-color: ${Color.lightGreen};
    color: ${Color.deepGray};
  `)}

  ${styleWhenTrue(prop("isWrong"), css`
    border: 1px solid ${Color.transparent};
    background-color: ${Color.lightPink};
  `)}

  ${styleWhenTrue(prop("disabled"), css`
    pointer-events: none;
  `)}
`;

export const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`;

export const ConfirmButton = styled(Button)`
  width: 100%;

  ${Media.tablet`
    width: auto;
    min-width: 200px;
  `}
`;

export const Circle = styled.div`
  width: 20px;
  height: 20px;
  border: 2px solid ${Color.dirtyWhite};
  border-radius: 50%;
  margin-right: 15px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  ${styleWhenTrue(prop("isChecked"), css`
    border: 6px solid ${Color.blue5};
    background-color: ${Color.white};
  `)}

  ${styleWhenTrue(prop("isCorrect"), css`
    border: 2px solid ${Color.green};
    background-color: ${Color.lightGreen};
  `)}

  ${styleWhenTrue(prop("isWrong"), css`
    border: 2px solid ${Color.red4};
    background-color: ${Color.lightPink};
  `)}
`;

export const CheckIcon = styled(CheckSvg)`
  width: 10px;
  height: 10px;
  fill: ${Color.green};
`;

export const CloseIcon = styled(CloseSvg)`
  width: 10px;
  height: 10px;
  fill: ${Color.red4};
`;

export const ErrorMessage = styled.p`
  font-size: 14px;
  line-height: 17px;
  color: ${Color.red};
  text-align: right;
  margin-top: 10px;
`;
