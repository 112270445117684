import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Loader } from "../../../../components/loader/loader.component";
import { useMediaListener } from "../../../../hooks/useMediaListener";
import { selectKnfHistory, selectStats } from "../../redux/idd.selectors";
import CarImg from "../../images/car.jpg";
import {
  Container,
  MobileImg,
  Wrapper,
  Title,
  Stats,
  Item,
  Icon,
  CoinsIcon,
  ClockIcon,
  ItemContent,
  ItemText,
  ItemTitle,
  ItemTextSmall,
} from "./header.styled";

export const Header = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaListener();
  const knfHistory = useSelector(selectKnfHistory);
  const knfId = knfHistory?.length ? knfHistory[0].value : null;
  const stats = useSelector(selectStats);

  const renderLoader = () => (
    <Loader size={isDesktop ? 36 : 24} />
  );

  return (
    <Container>
      <MobileImg src={CarImg} />
      <Wrapper>
        <Title>{t("idd.header.title")}</Title>
        <Stats>
          {knfId && (
            <Item>
              <Icon>
                <CoinsIcon />
              </Icon>
              <ItemContent>
                <ItemText>{knfId}</ItemText>
                <ItemTitle>{t("idd.header.knfNr")}</ItemTitle>
              </ItemContent>
            </Item>
          )}
          <Item>
            <Icon>
              <ClockIcon />
            </Icon>
            <ItemContent>
              {stats?.totalMinutes ? (
                <ItemText>
                  {stats.collectedMinutes} <ItemTextSmall>/ {stats.totalMinutes}</ItemTextSmall>
                </ItemText>
              ) : renderLoader()}
              <ItemTitle>{t("idd.header.minutes")}</ItemTitle>
            </ItemContent>
          </Item>
        </Stats>
      </Wrapper>
    </Container>
  );
};
