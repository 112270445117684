import { nodeApi } from "../../../api";
import { ActionType } from "../../../redux/websocket/websocket.constants";
import { store } from "../../../store";
import { FlipchartAction } from "../components/flipchart/flipchart.constants";
import { WebinarsTypes } from "../redux/webinars.reducer";
import { MessageType } from "../webinars.constants";

const storeDispatch = (value) => {
  store.dispatch({ type: ActionType.WS_SEND_MESSAGE, value: JSON.stringify(value) });
};

export const sendMessage = (message, receiverId) => {
  const value = {
    command: MessageType.SendChatMessage,
    data: {
      recipientParticipantId: receiverId ? receiverId : undefined,
      message,
    },
  };
  storeDispatch(value);
};

export const sendPermissionRequest = (streamType) => {
  const value = {
    command: MessageType.PermissionRequest,
    data: { streamType },
  }
  storeDispatch(value);
};

export const sendPermissionResponse = (streamType, participantId, accepted) => {
  const value = {
    command: MessageType.PermissionResponse,
    data: {
      streamType,
      participant: participantId,
      accepted,
    },
  };
  storeDispatch(value);
};

export const startLive = (room) => {
  const value = {
    command: MessageType.StartLive,
    data: { room },
  };
  storeDispatch(value);
};

export const endLive = () => {
  const value = {
    command: MessageType.EndLive,
    data: {},
  };
  storeDispatch(value);
};

export const stopStream = (stream) => {
  const value = {
    command: MessageType.StopStream,
    data: { stream },
  };
  storeDispatch(value);
};

export const startRecording = () => {
  const value = {
    command: MessageType.StartRecording,
    data: {}
  };
  storeDispatch(value);
};

export const pauseRecording = () => {
  const value = {
    command: MessageType.PauseRecording,
    data: {}
  };
  storeDispatch(value);
};

export const leaveRoom = () => {
  const value = {
    command: MessageType.LeaveRoom,
    data: {},
  };
  storeDispatch(value);
};

export const acceptGroupAssign = (groupId) => {
  const value = {
    command: MessageType.AcceptGroupAssign,
    data: { groupId },
  };
  storeDispatch(value);
};

export const getRoomInfo = () => {
  const value = {
    command: MessageType.GetRoomInfo,
    data: {},
  };
  storeDispatch(value);
};

export const sendFlipchartAddElement = (element, elementId) => {
  const value = {
    command: MessageType.FlipchartDraw,
    data: {
      data: {
        element,
        elementId,
        action: FlipchartAction.Add,
      },
    },
  };
  storeDispatch(value);
};

export const sendFlipchartModifyElement = (element, elementId) => {
  const value = {
    command: MessageType.FlipchartDraw,
    data: {
      data: {
        element,
        elementId,
        action: FlipchartAction.Modify,
      },
    },
  };
  storeDispatch(value);
};

export const sendFlipchartRemoveElement = (elementId) => {
  const value = {
    command: MessageType.FlipchartDraw,
    data: {
      data: {
        elementId,
        action: FlipchartAction.RemoveElement,
      },
    },
  };
  storeDispatch(value);
};

export const sendFlipchartClearCanvas = () => {
  const value = {
    command: MessageType.FlipchartDraw,
    data: {
      data: {
        action: FlipchartAction.ClearCanvas,
      },
    },
  };
  storeDispatch(value);
};

export const sendFlipchartState = (state) => {
  const value = {
    command: MessageType.FlipchartState,
    data: {
      data: state,
    },
  };
  storeDispatch(value);
};

export const roomRedirect = (roomId) => {
  const value = {
    command: MessageType.RoomRedirecting,
    data: { roomId },
  };
  storeDispatch(value);
};

export const sendPing = () => {
  const value = {
    command: MessageType.Ping,
  };
  storeDispatch(value);
};

export const openRoom = (room) => {
  return nodeApi.post("/rooms/groups", room);
};

export const closeRoom = (roomId) => {
  return nodeApi.post(`/rooms/groups/close/${roomId}`);
};

export const kickUser = (roomId, userId) => {
  return nodeApi.post(`/rooms/groups/kick/${roomId}/${userId}`);
};

export const assignUserToRoom = (roomId, userId) => {
  return nodeApi.post(`/rooms/groups/assign/${roomId}/${userId}`);
};

export const leaveGroup = () => {
  return nodeApi.post("/rooms/groups/leave");
};

export const openFlipchart = () => {
  return nodeApi.post("/rooms/flipcharts");
};

export const closeFlipchart = () => {
  return nodeApi.post("/rooms/flipcharts/close");
};

export const connectFlipchart = () => {
  return nodeApi.post("/rooms/flipcharts/connect");
};

export const muteStream = (streamType, participantId) => {
  return nodeApi.post(`/rooms/mute/${streamType}/${participantId}`);
};

export const fetchChatMessages = () => {
  return nodeApi.get("/rooms/chats");
};

const createFileFormData = (file, receiverId) => {
  const formData = new FormData();
  formData.append("file", file);

  if (receiverId) {
    formData.append("recipientParticipantId", receiverId);
  }
  return formData;
};

export const sendFile = (file, receiverId) => {
  return nodeApi.post("/rooms/chats/send-file", createFileFormData(file, receiverId), {
    onUploadProgress: (data) => {
      store.dispatch({
        type: WebinarsTypes.SET_FILE_UPLOAD_PROGRESS,
        fileUploadProgress: data.loaded / data.total,
      });
    },
  }).catch((error) => {
    if (error.response?.status === 400) {
      return null;
    }
  });
};

export const muteAudio = (audioMuted) => {
  return nodeApi.post(`/rooms/${audioMuted ? "mute" : "unmute"}/audio`);
};
