import styled from "styled-components";

import { ReactComponent as UmbrellaSvg } from "../../../../images/umbrella.svg";
import { hexToRgba } from "../../../../helpers/colors";
import { Color, Media } from "../../../../theme";

export const Container = styled.div``;

export const Content = styled.div`
  background-color: ${Color.blue7};
  border: 1px solid ${Color.dirtyWhite};
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 20px;
  gap: 20px;

  ${Media.tablet`
    justify-content: flex-start;
  `}

  ${Media.desktop`
    padding: 24px 35px;
    gap: 35px;
  `}
`;

export const CompanyList = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 24px;
  justify-content: center;
  align-items: center;

  ${Media.tablet`
    justify-content: flex-start;
    row-gap: 16px;
  `}
`;

export const CompanyLogo = styled.img`
  display: block;
  height: 22px;
  width: auto;
`;

export const StyledIcon = styled(UmbrellaSvg)`
  border: 1px solid ${hexToRgba(Color.blue6, 0.4)};
  border-radius: 2px;
  padding: 10px;
  width: 50px;
  height: 50px;
  display: none;
  flex-shrink: 0;

  path {
    stroke: ${Color.blue6};
  }

  ${Media.tablet`
    display: block;
  `}

  ${Media.desktop`
    width: 60px;
    height: 60px;
  `}
`;
