import React, { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { useModuleConfig, ModuleType } from "../";

import { Header } from "./components/header/header.component";
import { LessonList } from "./components/lessonList/lessonList.component";
import { FilesToDownload } from "./components/filesToDownload/filesToDownload.component";
import { RegistryHistory } from "./components/registryHistory/registryHistory.component";
import { IddActions } from "./redux/idd.reducer";
import { selectKnfHistory } from "./redux/idd.selectors";
import { Container } from "./idd.styled";

const Idd = () => {
  const config = useModuleConfig(ModuleType.Idd);
  const dispatch = useDispatch();
  const knfHistory = useSelector(selectKnfHistory);

  useEffect(() => {
    dispatch(IddActions.getInitialData());
    dispatch(IddActions.getContents());
  }, []);

  return (
    <ThemeProvider theme={config.theme}>
      <Container>
        <Header />
        <LessonList />
        <FilesToDownload />
        {!!knfHistory?.length && (
          <RegistryHistory />
        )}
      </Container>
    </ThemeProvider>
  );
};

export default Idd;
