export const Color = {
  brandBasic: "#000000",
  whiteHover: "#fdfdfd",
  almostWhite: "#f5f6fa",
  dirtyWhite: "#eceeee",
  gray1: "#c5c5c7",
  gray2: "#adadad",
  gray3: "#bababa",
  gray4: "#a7a9ac",
  gray5: "#e7e7e7",
  gray6: "#4d4d4d",
  gray7: "#d1d1d3",
  gray8: "#eeeff3",
  gray9: "#8e8e8e",
  gray10: "#7a8696",
  gray11: "#d6dadf",
  footerGray: "#b3b3b3",
  lightBlue2: "#00A1E0",
  blueLighter: "#13536f",
  blue: "#004563",
  blue2: "#6790C9",
  blue3: "#bedafa",
  blue4: "#34537E",
  blue5: "#338fce",
  blue6: "#EBF4FA",
  blue7: "#485f80",
  lightBlue: "#E6ECF5",
  purple: "#a83e6c",
  red: "#cc0031",
  red1: "#bc5f68",
  red2: "#f7d7da",
  red3: "#f4c6cb",
  red4: "#9F0032",
  red5: "#f44336",
  red6: "#D0505E",
  gold: "#f3bc18",
  bronze: "#cd7f32",
  yellow: "#e29e39",
  yellow2: "#f5c112",
  green: "#86a94e",
  green2: "#b0ca93",
  green3: "#CEDCB7",
  orange: "#EAB054",
  lightGreen: "#daebd9",
  veryLightGreen: "#e7f2e7",
  pink: "#e20071",
  pink2: "#fdc4dd",
  lightPink: "#fae5ea",
  veryLightPink: "#fbeaee",
  veryLightPink2: "#fefafb",
  darkPink: "#ce0e3c",
  deepPurple: "#A83E6C",
  maroon: "#ad1c4e",
  semiDarkGray: "#4c4c4c",
  darkGray: "#3d3d3c",
  deepGray: "#252122",
  almostBlack: "#121210",
  black: "#000000",
  silver: "#bbbbbb",
  brown: "#ac8156",
  white: "#ffffff",
  platformPrimary: "#000000",
  transparent: "transparent",
  lightYellow: "#fbf6d9",
  lightYellow2: "#fff6e7",
  veryLightViolet: "#f3f6fe",
  veryLightViolet2: "#eef1fe",
  lightViolet: "#e7ecfe",
  lightViolet2: "#d9e1fd",
  lighterViolet: "#85a1f8",
  violet: "#708be6",
  violet2: "#5473cc",
  error: "#BD081C",
};
