import styled from "styled-components";

import { Color, FontWeight } from "../../../../theme";
import { ReactComponent as CompetenceSvg } from "../../images/competence.svg";

export const Container = styled.div`
  background-color: ${Color.white};
  padding: 20px;
`;

export const Header = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 8px;
`;

export const Icon = styled(CompetenceSvg)`
  width: 46px;
  height: auto;
  flex-shrink: 0;
`;

export const Title = styled.p`
  color: ${Color.deepGray};
  font-size: 15px;
  font-weight: ${FontWeight.Bold};
  line-height: 1.3;
`;
