import React, { Component } from "react";
import i18n from "i18next";

import { Icon } from "../../../UIElements/icons";
import { breakpointsArray } from "../../../UIGlobals";
import { colors } from "../../../UIGlobals";

import { TwoTitledAnswersQuiz } from "./twoTitledAnswersQuiz/twoTitledAnswersQuiz.component";
import { ThumbsQuiz } from "./thumbsQuiz/thumbsQuiz.component";
import { TrueOrFalseQuiz } from "./trueOrFalseQuiz/trueOrFalseQuiz.component";
import { FourAnswersQuiz } from "./fourAnswersQuiz/fourAnswersQuiz.component";
import { MatchWithGroupsQuiz } from "./matchWithGroupsQuiz/matchWithGroupsQuiz.component";
import { AgreementQuiz } from "./agreementQuiz/agreementQuiz.component";
import { ThreeAnswersQuiz } from "./threeAnswersQuiz/threeAnswersQuiz.component";
import {
  IntroductionText,
  HeaderText,
  LightHeaderText,
  Container,
  HeaderContainer,
  Button,
  IconText,
  DownloadButton,
} from "./quizSlides.styled";
import { SlideType } from "./quizSlides.constants";

class QuizSlides extends Component {
  state = {
    loading: true,
    activeInteraction: 0,
    activePart: 0,
    disabledButton: false
  };

  componentDidMount() {
    if (this.props.baseUrl + this.props.config) {
      fetch(this.props.baseUrl + this.props.config)
        .then(data => data.json())
        .then(data => this.setState({ ...data }, this.setState({ loading: false })));
    }
  }

  setDisabledButton = isCorrect =>
    this.setState({
      disabledButton: isCorrect
    });

  goNext() {
    const { activeInteraction, activePart, interaction } = this.state;
    this.setState({
      activePart: activePart >= interaction[activeInteraction].parts.length - 1 ? 0 : activePart + 1,
      activeInteraction:
        activePart >= interaction[activeInteraction].parts.length - 1 ? activeInteraction + 1 : activeInteraction
    });
  }

  renderInteraction(data) {
    const { type, text } = data;
    const { activeInteraction } = this.state;
    const params = {
      key: text,
      data: data,
      setDisabledButton: this.setDisabledButton
    }

    switch (type) {
    case SlideType.ThumbsQuiz:
      return <ThumbsQuiz {...params} />;
    case SlideType.MatchWithGroupsQuiz:
      return <MatchWithGroupsQuiz key={activeInteraction} data={data} setDisabledButton={this.setDisabledButton} />;
    case SlideType.TwoTitledAnswersQuiz:
      return <TwoTitledAnswersQuiz {...params} />;
    case SlideType.FourAnswersQuiz:
      return <FourAnswersQuiz {...params} />;
    case SlideType.TrueOrFalseQuiz:
      return <TrueOrFalseQuiz {...params} />;
    case SlideType.YesOrNoQuiz:
      return <TrueOrFalseQuiz yesOrNo {...params} />;
    case SlideType.HorizontalOrVertical:
      return <AgreementQuiz {...params} />;
    case SlideType.ThreeAnswersQuiz:
      return <ThreeAnswersQuiz {...params} />;
    case SlideType.IsAllowedQuiz:
      return <TrueOrFalseQuiz isAllowedQuiz {...params} />;
    case SlideType.Introduction:
      return (
        <IntroductionText>
          {text.split("\n").map((part, index) => (
            <p key={index}>{part}</p>
          ))}
        </IntroductionText>
      );
    case SlideType.IntroductionWithFile:
      return <IntroductionText withFile>{text}</IntroductionText>;
    default:
      return <div />;
    }
  }

  onNextButtonClick = () => {
    const { activeInteraction, activePart, interaction } = this.state;

    const lastQuizSlide =
      interaction &&
      activeInteraction === interaction.length - 1 &&
      activePart === interaction[activeInteraction].parts.length - 1;

    if (lastQuizSlide) this.props.callComplete();
    else {
      this.setDisabledButton(false);
      this.goNext();
    }
  };

  renderHeaderActivePart = () => {
    const { activePart, interaction, activeInteraction } = this.state;
    const parts = interaction[activeInteraction].parts;
    const isIntroduction = parts[0].type === SlideType.Introduction || parts[0].type === SlideType.IntroductionWithFile;
    const partsLength = isIntroduction ? parts.length - 1 : parts.length;
    const active = isIntroduction ? activePart : activePart + 1;

    return (
      <HeaderText>
        {i18n.t("quizSlides.quizSlides.question")} {active}<LightHeaderText>|{partsLength}</LightHeaderText>
      </HeaderText>
    );
  };

  handleDownloadClick = (fileUrl) => {
    window.open(fileUrl, "_blank");
  }

  render() {
    const { loading, activeInteraction, activePart, disabledButton, interaction } = this.state;
    const slide = interaction ? interaction[activeInteraction].parts[activePart] : null;
    const isIntroduction = slide && (slide.type === SlideType.Introduction || slide.type === SlideType.IntroductionWithFile);
    if (isIntroduction && !disabledButton) this.setDisabledButton(true);

    const iconColor = !disabledButton
      ? colors().gray11
      : window.innerWidth <= breakpointsArray.s
        ? colors().blue3
        : colors().white;

    return loading || !slide ? (
      "Ładuję"
    ) : (
      <Container blueBackground={isIntroduction}>
        <HeaderContainer>
          <HeaderText>{slide.header}</HeaderText>
          {!isIntroduction && this.renderHeaderActivePart()}
        </HeaderContainer>
        {this.renderInteraction(slide)}
        {slide.type === SlideType.IntroductionWithFile && <DownloadButton onClick={() => this.handleDownloadClick(slide.fileUrl)}>
          <IconText>
            {i18n.t("quizSlides.quizSlides.downloadPdf")}
          </IconText>
          <Icon icon="status_download" size={14} color={colors().blue2} />
        </DownloadButton>}
        <Button blueBackground={isIntroduction} disabled={!disabledButton} onClick={this.onNextButtonClick}>
          <IconText>
            {isIntroduction ? i18n.t("quizSlides.quizSlides.start") : i18n.t("quizSlides.quizSlides.next")}
          </IconText>
          <Icon icon="interaction_arrow_right" size={14} color={iconColor} />
        </Button>
      </Container>
    );
  }
}

export default QuizSlides;
