import React from "react";
import styled from "styled-components";

import { breakpoints, breakpointsArray, colors } from "../../../../UIGlobals";
import { Icon } from "../../../../UIElements/icons";

export const Container = styled.div`
  padding: 115px 130px 200px;
  @media (${breakpoints().maxL}) {
    padding: 60px 0;
  }
  @media (${breakpoints().maxS}) {
    padding: 60px 0 0;
  }
`;

export const Title = styled.div`
  font-size: 20px;
  line-height: 33px;
  margin-bottom: 25px;
`;

const BorderedTile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  width: 100%;
  padding: 17px;
  border: 1px solid ${props => (props.wrongTheme ? colors().purple : colors().blue3)};
  @media (${breakpoints().maxL}) {
    padding: 36px 25px;
    font-size: 16px;
  }
  @media (${breakpoints().maxM}) {
    line-height: 20px;
  }
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  ${props => !props.wrongTheme && !props.correctTheme && `border: 1px solid ${colors().blue3}`};
  border-radius: 50%;
  margin-right: 17px;
  background-color: ${props =>
  props.wrongTheme
    ? "rgba(168, 62, 108, 0.3)"
    : props.correctTheme
      ? "rgba(103, 144, 201, 0.3)"
      : ""
  };
  @media (${breakpoints().maxM}) {
    width: 50px;
    height: 50px;
  }
`;

export const WrongAnswerComment = styled.div`
  font-size: 14px;
  margin-top: 16px;
  line-height: 18px;
  color: ${colors().purple};
`;

export const ValidAnswerComment = styled(WrongAnswerComment)`
  color: ${colors().blue2};
`;

export const TextTileOption = ({ onClick, wrongTheme, correctTheme, text }) => (
  <BorderedTile wrongTheme={wrongTheme} correctTheme={correctTheme} onClick={onClick}>
    <Circle wrongTheme={wrongTheme} correctTheme={correctTheme}>
      {!!wrongTheme && (
        <Icon
          icon="close_fat"
          size={window.innerWidth < breakpointsArray.s ? "30" : "15"}
          color={colors().purple}
        />
      )}
      {!!correctTheme && (
        <Icon
          icon="status_checkbox"
          size={window.innerWidth < breakpointsArray.s ? "25" : "15"}
          color={colors().blue2}
        />
      )}
    </Circle>
    {text}
  </BorderedTile>
);
