import React, { Component } from "react";

import { Row, SentenceTile, Container } from "./matchWithGroupsQuiz.styled";
import { Dropdown } from "./dropdown.component";

export class MatchWithGroupsQuiz extends Component {
  state = {
    answers: Array(this.props.data.sentences.length).fill(null),
    dropdownsShowState: Array(this.props.data.sentences.length).fill(false),
  };

  checkIfAllCorrect = () => this.state.answers.every(answer => !!answer);

  toggleShowDropdown = (index) =>
    this.setState(
      prevState => {
        const { dropdownsShowState } = prevState;
        const dropdownStates = Array(dropdownsShowState.length).fill(false);
        dropdownStates[index] = !dropdownsShowState[index];
        return { dropdownsShowState: dropdownStates };
      }
    );

  updateAnswer = (index, value) =>
    this.setState(
      prevState => {
        const newAnswers = [...prevState.answers];
        newAnswers[index] = value;
        return { answers: newAnswers };
      },
      () => this.props.setDisabledButton(this.checkIfAllCorrect())
    );

  filterCorrectAnsweredOptions = () => {
    const { sentences, groups } = this.props.data;
    const { answers } = this.state;
    const filteredOptions = [];

    answers.forEach((answer, index) => {
      if (answer) {
        const correctAnswered = groups[sentences[index].group];
        filteredOptions.push(correctAnswered.text);
      }
    });

    return filteredOptions;
  };

  renderTilesRows = () => {
    const { sentences, groups } = this.props.data;
    const { answers, dropdownsShowState } = this.state;
    const filteredAnswers = this.filterCorrectAnsweredOptions();
    return sentences.map((sentence, index) => (
      <Row key={sentence.text}>
        <SentenceTile wrongTheme={answers[index] === false} correctTheme={answers[index]}>
          {sentence.text}
        </SentenceTile>
        <Dropdown
          sentenceIndex={index}
          updateAnswer={this.updateAnswer}
          correctText={groups[sentence.group].text}
          options={groups}
          toggleShow={this.toggleShowDropdown}
          show={dropdownsShowState[index]}
          correctAnsweredOptions={filteredAnswers}
        />
      </Row>
    ));
  };

  render() {
    return <Container>{this.renderTilesRows()}</Container>;
  }
}
