import React, { Component } from "react";
import i18n from "i18next";

import { colors } from "../../../../UIGlobals";

import { Icon } from "../../../../UIElements/icons";
import { DropdownContainer, DropdownButton, Circle, DropdownContent } from "./dropdown.styled";

export class Dropdown extends Component {
  state = { chosenText: "" };

  toggleShow = () => this.props.toggleShow(this.props.sentenceIndex);

  handleAnswerClick = (text) => {
    const { updateAnswer, sentenceIndex, correctText } = this.props;
    this.setState({ chosenText: text });
    this.toggleShow();
    updateAnswer(sentenceIndex, text === correctText);
  };

  render() {
    const { options, correctText, correctAnsweredOptions, show } = this.props;
    const { chosenText } = this.state;
    const correctChosen = chosenText === correctText;
    const wrongChosen = chosenText.length && chosenText !== correctText;

    return (
      <DropdownContainer>
        <DropdownButton onClick={this.toggleShow} correctTheme={correctChosen} wrongTheme={wrongChosen}>
          {chosenText.length ? chosenText : i18n.t("matchWithGroupQuiz.dropdown.choose")}
          {correctChosen ? (
            <Circle>
              <Icon icon="status_checkbox" size={12} color={colors().blue2} />
            </Circle>
          ) : (
            <Icon icon="arrow_down" size={14} color={wrongChosen ? colors().purple : colors().blue2} />
          )}
        </DropdownButton>
        <DropdownContent show={show}>
          {options
            .filter(option => !correctAnsweredOptions.includes(option.text))
            .map(({ text }) => (
              <div key={text} onClick={() => this.handleAnswerClick(text)}>
                {text}
              </div>
            ))}
        </DropdownContent>
      </DropdownContainer>
    );
  }
}
