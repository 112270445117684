import styled from "styled-components";

import { breakpoints, colors } from "../../../../UIGlobals";
import { SentenceTile } from "./matchWithGroupsQuiz.styled";

export const DropdownContent = styled.div`
  display: ${props => (props.show ? "block" : "none")};
  position: absolute;
  background-color: ${colors().white};
  width: 100%;
  box-shadow: 0 1px 10px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  & > div {
    cursor: pointer;
    color: ${colors().black};
    padding: 16px;
    font-size: 13px;
    text-decoration: none;
    display: block;
    &:not(:first-of-type) {
      border-top: 1px solid ${colors().gray11};
    }
  }
  @media (${breakpoints().maxM}) {
    font-size: 16px;
  }
`;

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const DropdownButton = styled(SentenceTile)`
  width: 320px;
  color: ${colors().gray10};
  font-style: normal;
  background-color: ${colors().white};
  cursor: pointer;
  color: ${props => (
  props.correctTheme
    ? colors().blue2
    : props.wrongTheme
      ? colors().purple
      : colors().gray10)};
  & > *:last-child {
    position: absolute;
    right: 15px;
  }
  @media (${breakpoints().maxM}) {
    padding-right: 40px;
  }
`;

export const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 35px;
  background-color: rgba(103, 144, 201, 0.3);
`;
