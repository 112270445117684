import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { selectUserData } from "../../../../userContext/redux/userContext.selectors";
import { ButtonColor } from "../../../../components/button/button.constants";
import { simpleDateFromTimestamp } from "../../../../helpers/date";
import { AppConfig } from "../../../../appConfig";
import { ListDesktopDownloadButton, ListDesktopDownloadIcon } from "../filesToDownload/filesToDownload.styled";
import { getUriType } from "../passHistory/passHistory.utils";

import {
  Container,
  Cell,
  Thumbnail,
  Title,
  TimeIcon,
  Time,
} from "./passItemDesktop.styled";

export const PassItemDesktop = ({
  id,
  title,
  imageSrc,
  minutes,
  type,
  passTimestamp,
}) => {
  const { t } = useTranslation();
  const user = useSelector(selectUserData);
  const date = simpleDateFromTimestamp(passTimestamp);
  const uriType = getUriType(type.type);

  const handleDownload = () => {
    window.open(`${AppConfig.api}/download/${uriType}/${id}?X-AUTH-TOKEN=${user.token}`);
  };

  return (
    <Container>
      <Cell>
        <Thumbnail src={imageSrc} />
        <Title>{title}</Title>
      </Cell>
      <Cell>
        <TimeIcon />
        <Time>{minutes} {t("idd.passItemDesktop.minutes")}</Time>
      </Cell>
      <Cell>{type.label}</Cell>
      <Cell>{date}</Cell>
      <Cell>
        <ListDesktopDownloadButton
          color={ButtonColor.Primary}
          onClick={handleDownload}
          disabled={!uriType}
        >
          {t("idd.passItemDesktop.download")}
          <ListDesktopDownloadIcon />
        </ListDesktopDownloadButton>
      </Cell>
    </Container>
  );
};
