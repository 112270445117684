import styled, { css } from "styled-components";
import { prop } from "ramda";
import { Link } from "react-router-dom";

import { ReactComponent as CupSvg } from "../../../../images/cup.svg";
import { ReactComponent as ArrowSvg } from "../../../../images/arrow.svg";
import { styleWhenTrue } from "../../../../helpers/rendering";
import { Color, FontWeight, Media } from "../../../../theme";

export const Container = styled.div``;

export const Wrapper = styled(Link)`
  background-color: ${Color.white};
  height: 140px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 24px 20px;
  text-decoration: none;

  ${Media.desktop`
    gap: 35px;
    padding: 38px 35px;
  `}

  ${styleWhenTrue(prop("$isDisabled"), css`
    pointer-events: none;
  `)}
`;

export const StyledIcon = styled(CupSvg)`
  width: 50px;
  height: 50px;
  border: 1px solid ${Color.dirtyWhite};
  border-radius: 2px;
  padding: 10px;
  flex-shrink: 0;

  ${Media.desktop`
    width: 60px;
    height: 60px;
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const Title = styled.p`
  font-size: 16px;
  font-weight: ${FontWeight.SemiBold};
  line-height: 1.25;
  color: ${Color.deepGray};
`;

export const Text = styled.p`
  color: ${Color.gray9};
  font-size: 13px;
  line-height: 1.3;
`;

export const Date = styled(Text)`
  color: ${Color.blue5};

  ${styleWhenTrue(prop("$isError"), css`
    color: ${Color.red1};
  `)}
`;

export const ArrowIcon = styled(ArrowSvg)`
  fill: ${Color.gray9};
  rotate: 180deg;
  height: 22px;
  width: auto;
  margin-left: auto;
  flex-shrink: 0;
`;
