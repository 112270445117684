import styled from "styled-components";

import { breakpoints, colors } from "../../../../UIGlobals";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  padding-bottom: 70px;
`;

export const SentenceTile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(230, 236, 245, 0.3);
  border: 1px solid ${props => (
  props.wrongTheme
    ? colors().purple
    : props.correctTheme
      ? colors().blue3
      : "#bfcee8")};
  width: 450px;
  font-size: 13px;
  text-align: center;
  height: 50px;
  color: ${colors().semiDarkGray};
  padding: 0 35px 0 25px;
  line-height: 17px;
  ${props => (props.wrongTheme || props.correctTheme) && "font-weight: bold"};
  @media (${breakpoints().maxM}) {
    width: 100%;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    height: auto;
    padding: 24px 22px;
    justify-content: flex-start;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  @media (${breakpoints().maxM}) {
    width: 100%;
    flex-direction: column;
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
`;

