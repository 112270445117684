import styled from "styled-components";

import { Media } from "../../../../theme";
import { PassItemMobile } from "../passItemMobile/passItemMobile.component";
import { headerItemStyles } from "../filesToDownload/filesToDownload.styled";
import { ListPagination } from "../listPagination/listPagination.component";

export const Container = styled.div``;

export const HeaderItem = styled.div`
  ${headerItemStyles}

  &:nth-of-type(1) {
    flex: 3;
  }

  &:nth-of-type(2) {
    margin-left: auto;
  }

  &:nth-of-type(5) {
    min-width: 145px;
  }
`;

export const StyledPassItemMobile = styled(PassItemMobile)`
  & + & {
    margin-top: 4px;
  }
`;

export const StyledListPagination = styled(ListPagination)`
  margin-top: 25px;

  ${Media.tabletWide`
    margin-top: 15px;
  `}
`;