import React, { Component } from "react";
import i18n from "i18next";

import { colors } from "../../../../UIGlobals";
import { optionClickHandler } from "../quizHelpers";

import { Icon } from "../../../../UIElements/icons";
import {
  Container,
  Title,
  BorderedContainer,
  Row,
  AnswerBox,
  Circle,
  AnswerBoxTitle,
  ValidAnswerComment,
  WrongAnswerComment,
  RenderCorrectComment
} from "./trueOrFalseQuiz.styled";

export class TrueOrFalseQuiz extends Component {
  state = {
    wrongAnswerComment: "",
    correctAnswerComment: "",
    clickedIndex: null
  };

  onOptionClick = (isCorrect, index) => {
    const { data, setDisabledButton } = this.props;
    const { correctText, incorrectText } = data;
    this.setState(optionClickHandler(isCorrect, correctText, incorrectText, setDisabledButton, index));
  }

  getIconColor = (whenCorrect, whenWrong, whenGreyed) => whenCorrect
    ? colors().white
    : whenWrong
      ? colors().purple
      : whenGreyed
        ? colors().gray11
        : colors().blue2;

  capitalizeSentence = (sentence) => sentence.replace(/^./, str => str.toUpperCase());

  render() {
    const { data, yesOrNo, isAllowedQuiz } = this.props;
    const { text, correctAnswer } = data;
    const { correctAnswerComment, wrongAnswerComment, clickedIndex } = this.state;

    const wrongFirstClicked = clickedIndex === 0 && !correctAnswer;
    const correctFirstClicked = clickedIndex === 0 && correctAnswer;
    const wrongSecondClicked = clickedIndex === 1 && correctAnswer;
    const correctSecondClicked = clickedIndex === 1 && !correctAnswer;

    const correctAnswerClicked = correctFirstClicked || correctSecondClicked;
    const wrongAnswerClicked = wrongFirstClicked || wrongSecondClicked;

    const firstBoxText = yesOrNo
      ? i18n.t("trueOrFalseQuiz.trueOrFalseQuiz.yes")
      : isAllowedQuiz
        ? i18n.t("trueOrFalseQuiz.trueOrFalseQuiz.allowed")
        : i18n.t("trueOrFalseQuiz.trueOrFalseQuiz.true");

    const secondBoxText = yesOrNo
      ? i18n.t("trueOrFalseQuiz.trueOrFalseQuiz.no")
      : isAllowedQuiz
        ? i18n.t("trueOrFalseQuiz.trueOrFalseQuiz.notAllowed")
        : i18n.t("trueOrFalseQuiz.trueOrFalseQuiz.false");

    return (
      <Container>
        <Title>{this.capitalizeSentence(text)}:</Title>
        {correctAnswerClicked && yesOrNo && !!correctAnswerComment ? (
          <BorderedContainer>
            <RenderCorrectComment comment={correctAnswerComment} />
          </BorderedContainer>
        ) : (
          <>
            <Row>
              <AnswerBox
                greyed={correctSecondClicked}
                wrongTheme={wrongFirstClicked}
                onClick={() => this.onOptionClick(correctAnswer, 0)}
              >
                <Circle wrongTheme={wrongFirstClicked} correctTheme={correctFirstClicked} greyed={correctSecondClicked}>
                  <Icon
                    icon="status_checkbox"
                    size="22"
                    color={this.getIconColor(correctFirstClicked, wrongFirstClicked, correctSecondClicked)}
                  />
                </Circle>
                <AnswerBoxTitle
                  greyed={correctSecondClicked}
                  bold={wrongFirstClicked || correctFirstClicked}
                  blue={correctFirstClicked}
                >
                  {firstBoxText}
                </AnswerBoxTitle>
              </AnswerBox>
              <AnswerBox
                greyed={correctFirstClicked}
                wrongTheme={wrongSecondClicked}
                onClick={() => this.onOptionClick(!correctAnswer, 1)}
              >
                <Circle
                  wrongTheme={wrongSecondClicked}
                  correctTheme={correctSecondClicked}
                  greyed={correctFirstClicked}
                >
                  <Icon
                    icon="close_fat"
                    size="18"
                    color={this.getIconColor(correctSecondClicked, wrongSecondClicked, correctFirstClicked)}
                  />
                </Circle>
                <AnswerBoxTitle
                  greyed={correctFirstClicked}
                  bold={wrongSecondClicked || correctSecondClicked}
                  blue={correctSecondClicked}
                >
                  {secondBoxText}
                </AnswerBoxTitle>
              </AnswerBox>
            </Row>
            {correctAnswerClicked && <ValidAnswerComment>{correctAnswerComment}</ValidAnswerComment>}
            {wrongAnswerClicked && <WrongAnswerComment>{wrongAnswerComment}</WrongAnswerComment>}
          </>
        )}
      </Container>
    );
  }
}
