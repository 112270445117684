import styled from "styled-components";

import { breakpoints } from "../../../UIGlobals";
import { colors } from "../../../UIGlobals";

export const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 650px;
  padding: 30px 40px 43px;
  background-color: ${props => (props.blueBackground ? colors().lightBlue : colors().white)};
  @media (${breakpoints().maxL}) {
    padding: 20px 20px 150px;
  }
  @media (${breakpoints().maxM}) {
    padding: 20px 20px 200px;
    height: 100%;
    min-height: 470px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 7px;
  border-bottom: 1px solid ${colors().blue2};
`;

export const HeaderText = styled.span`
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  line-height: 42px;
  color: ${colors().blue2};
`;

export const LightHeaderText = styled(HeaderText)`
  font-weight: 600;
  color: ${colors().blue3};
`;

export const IntroductionText = styled.div`
  font-size: 25px;
  line-height: 37px;
  font-weight: bold;
  color: ${colors().blue2};
  margin: 190px 190px 137px 130px;
  @media (${breakpoints().maxL}) {
    margin: 120px 80px;
  }
  @media (${breakpoints().maxM}) {
    margin: 62px 0 0;
    font-size: 20px;
    line-height: 32px;
  }
`;

export const Button = styled.button`
  position: absolute;
  right: 40px;
  bottom: 45px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 160px;
  padding: 17px 25px;
  border-radius: 30px;
  border: 1px solid ${colors().blue2};
  background-color: ${props => (props.disabled ? "transparent" : colors().blue2)};
  color: ${props => (props.disabled ? colors().gray11 : colors().white)};
  box-shadow: ${props => !props.disabled && "0 2px 5px 0 rgba(103, 144, 201, 0.3)"};
  ${props => props.disabled && `border: 1px solid ${colors().gray11}`};
  ${props => !props.disabled && "cursor: pointer"};
  @media (${breakpoints().maxS}) {
    width: calc(100% - 40px);
    max-width: 290px;
    right: 20px;
    background-color: ${props => (props.blueBackground ? colors().blue2 : "transparent")};
    color: ${props => (
  props.disabled
    ? colors().gray11
    : props.blueBackground
      ? colors().white
      : colors().semiDarkGray)};
    border: ${props => (props.disabled ? `3px solid ${colors().gray11}` : `1px solid ${colors().blue3}`)};
    box-shadow: none;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
`;

export const IconText = styled.span`
  font-size: 12px;
  font-weight: bold;
`;

export const DownloadButton = styled(Button)`
  background-color: ${colors().white};
  color: ${colors().blue2};
  right: 210px;
  @media (${breakpoints().maxS}) {
    bottom: 100px;
  }
`;