import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { selectPassHistoryFilters } from "../../redux/idd.selectors";
import { PassHistory } from "../passHistory/passHistory.component";
import { CertificatesHistory } from "../certificatesHistory/certificatesHistory.component";

import {
  Container,
  Wrapper,
  SectionTitle,
  TabsRow,
  Tabs,
  Tab,
  YearFilter,
  StyledSeparator,
  BottomSections,
  StyledInsuranceCompanyBox,
  StyledPathsBox,
  StyledDropdownInput,
} from "./filesToDownload.styled";
import { TableTab } from "./filesToDownload.constants";

export const FilesToDownload = () => {
  const { t } = useTranslation();
  const passHistoryFilters = useSelector(selectPassHistoryFilters);
  const [activeTab, setActiveTab] = useState(TableTab.Certificates);
  const [yearFilter, setYearFilter] = useState(null);
  const parsedYearFilters = passHistoryFilters?.year?.map(({ year }) => ({
    id: year,
    label: year,
  }));

  useEffect(() => {
    if (!yearFilter && passHistoryFilters?.year) {
      setYearFilter(passHistoryFilters.year[0].year);
    }
  }, [passHistoryFilters]);

  const handleYearFilterChange = ({ target }) => {
    const year = parsedYearFilters?.find((year) => year.id === target.value);
    setYearFilter(year.id);
  };

  return (
    <Container>
      <Wrapper>
        <SectionTitle>{t("idd.filesToDownload.filesToDownload")}</SectionTitle>
        <TabsRow>
          <Tabs>
            <Tab
              $isActive={activeTab === TableTab.Certificates}
              onClick={() => setActiveTab(TableTab.Certificates)}
            >
              {t("idd.filesToDownload.certificates")}
            </Tab>
            <Tab
              $isActive={activeTab === TableTab.PassHistory}
              onClick={() => setActiveTab(TableTab.PassHistory)}
            >
              {t("idd.filesToDownload.passHistory")}
            </Tab>
          </Tabs>
          {activeTab === TableTab.PassHistory && parsedYearFilters && (
            <YearFilter>
              <StyledDropdownInput
                small
                valueId={yearFilter}
                value={yearFilter?.toString()}
                onChange={handleYearFilterChange}
                options={parsedYearFilters}
              />
            </YearFilter>
          )}
        </TabsRow>
        {activeTab === TableTab.Certificates && (
          <CertificatesHistory />
        )}
        {activeTab === TableTab.PassHistory && (
          <PassHistory yearFilter={yearFilter} />
        )}
        <StyledSeparator />
        <BottomSections>
          <StyledInsuranceCompanyBox />
          <StyledPathsBox />
        </BottomSections>
      </Wrapper>
    </Container>
  );
};
