import styled from "styled-components";

import { PageHeader as PageHeaderBase } from "../theme/typography";
import { colors, breakpoints } from "../UIGlobals";

const PageHeader = styled(PageHeaderBase)`
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom + "px" : "60px")};
  ${props => props.paddingLeft && "padding-left: " + props.paddingLeft + "px"};
  ${props => props.landscapePadding && `
    @media (${breakpoints().maxM}) {
      padding: ${props.landscapePadding};
    }
  `}
`;

const ListHeader = styled.h2`
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.11;
  text-align: left;
  color: ${colors().black};
  margin-bottom: 10px;
`;

const HeaderBadge = styled.div`
  padding: ${props => (props.padding ? props.padding : "40px 0 0 0")};
  display: flex;
  align-items: center;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom + "px" : "0")};
  ${props => (props.$hasBottomBorder && `border-bottom: 1px solid ${colors().dirtyWhite}`)};
  ${props => props.justifyContent && "justify-content: " + props.justifyContent};
  @media (${breakpoints().maxL}) {
    margin-top: ${props => (props.marginTop ? props.marginTop + "px" : "0")};
  }
`;

const MainTitle = styled.h1`
  font-size: 2.6rem;
  color: ${colors().black};
  font-weight: 700;
`;

const RequiredOption = styled.span`
  color: ${colors().gray4};
`;

export { PageHeader, ListHeader, HeaderBadge, MainTitle, RequiredOption };
