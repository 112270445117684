import styled, { css } from "styled-components";
import { prop } from "ramda";

import { ReactComponent as ArrowSvg } from "../../../../images/arrow.svg";
import { Color, FontWeight, Media } from "../../../../theme";
import { styleWhenTrue } from "../../../../helpers/rendering";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;

  ${Media.tabletWide`
    justify-content: flex-end;
  `}
`;

export const Pages = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  ${Media.tabletWide`
    gap: 5px;
  `}
`;

export const Page = styled.button`
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 2px;
  background-color: ${Color.white};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Color.gray9};
  font-weight: ${FontWeight.SemiBold};
  font-size: 12px;

  ${styleWhenTrue(prop("$isActive"), css`
    color: ${() => Color.brandBasic};
    font-weight: ${FontWeight.Bold};
  `)}
`;

const navButtonStyles = css`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Color.transparent};
  border: none;
`;

export const PrevButton = styled.button`
  ${navButtonStyles}
`;

export const NextButton = styled.button`
  ${navButtonStyles}
  rotate: 180deg;
`;

export const Arrow = styled(ArrowSvg)`
  height: 16px;
  fill: ${Color.gray9};
`;
