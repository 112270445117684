import React, { Component } from "react";
import i18n from "i18next";
import styled from "styled-components";

import { colors, breakpoints } from "../UIGlobals";

const Inner = styled.div`
  width: 100%;
    font-size: ${props => (props.min ? "11px" : "14px")};
    font-family: "Open Sans";
    line-height: ${props => (props.min ? "1.41" : "1.714")};
    max-width: 700px;
    padding: ${props => (props.min ? "15px 0px 15px 13px" : (props.padding ? props.padding : "20px"))};
    @media (${breakpoints().maxM}) {
        max-width: calc(100% - 60px);
    }
    
`;

const InnerBold = styled.span`
  font-weight: bold;
`;

const Logo = styled.svg`
  width: ${props => (props.width ? props.width : "44px")};
    display: block;
`;

const IconTableElement = styled.div`
    position: relative;
    margin-bottom: 30px;
`;

const IconRow = styled.div`
    position: relative;
    background: ${props => (props.min ? "#f5f6fab3" : colors().almostWhite)};
    padding: ${props => (props.min ? "8px" : "20px")};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1px;
    height: ${props => (props.min ? "55px" : "")};
    @media (${breakpoints().maxM}) {
        height: ${props => (props.min ? "70px" : "")};
    }
    @media (${breakpoints().maxS}) {
        height: ${props => (props.min ? "120px" : "")};
        padding: 10px;
  }
`;

const IconIcon = styled.div`
    background: #fff;
    border-radius: 50%;
    width: ${props => (props.min ? "35px" : "44px")};
    margin-left: 5px;
    box-shadow: 0px 0px 2px 1px ${colors().dirtyWhite};
    height: ${props => (props.min ? "32px" : "44px")};
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default class IconTable extends Component {

  render(){
    const { minVer } = this.props;
    return(
      <IconTableElement min={minVer}>
        <IconRow min={minVer}>
          <IconIcon min={minVer}>
            <Logo viewBox="0 0 48 48" width={(minVer ? "19px" : "22px")}>

              <path
                style={{ fill: colors().platformPrimary }}
                d="M48,24A24,24,0,0,0,24.38,0H24a24,24,0,0,0,0,48h.38A24,24,0,0,0,48,24ZM24.25,46h-.08c-2.75,0-5.61-3.82-7.27-10.1a49.66,49.66,0,0,1,7-.44,47.81,
                                47.81,0,0,1,7.56.54C29.82,42.2,27,46,24.25,46Zm-.33-12.55a50.41,50.41,0,0,0-7.47.5,48.58,48.58,0,0,1-.95-9H32.91v1A47.85,47.85,0,0,1,32,34,49.32,
                                49.32,0,0,0,23.92,33.45Zm-17.28,4A21.89,21.89,0,0,1,2,25H13.48a50.12,50.12,0,0,0,1,9.3,23.24,23.24,0,0,0-7.62,3Zm.25-27.26a21.43,21.43,0,0,0,7.69,
                                3A49.81,49.81,0,0,0,13.48,23H2A21.93,21.93,0,0,1,6.89,10.19ZM24.17,2h.08c2.66,0,5.43,3.6,7.1,9.54A48.86,48.86,0,0,1,24,12a51.19,51.19,0,0,1-7-.43C18.71,
                                5.62,21.5,2,24.17,2ZM24,14a51.51,51.51,0,0,0,7.85-.52A47.63,47.63,0,0,1,32.91,23H15.49a48.42,48.42,0,0,1,1-9.45A51.71,51.71,0,0,0,24,14Zm17.06-3.9A21.85,
                                21.85,0,0,1,46,23H34.92a49.6,49.6,0,0,0-1.12-9.87,22.19,22.19,0,0,0,7-2.86ZM34.92,25H46a6.73,6.73,0,0,1-.07,1,21.92,21.92,0,0,1-4.57,11.53,22.6,22.6,0,0,
                                0-7.43-3.1A49.81,49.81,0,0,0,34.92,25ZM39.69,8.6a20.15,20.15,0,0,1-6.38,2.58,22,22,0,0,0-4.18-8.57A22,22,0,0,1,39.69,8.6ZM19.37,2.5a21.76,21.76,0,0,0-4.3,
                                8.77,19.85,19.85,0,0,1-6.82-2.6A22,22,0,0,1,19.37,2.5ZM8,39a21.34,21.34,0,0,1,7-2.78,22.31,22.31,0,0,0,4.44,9.26A22,22,0,0,1,8,39Zm21.19,6.36a22.48,22.48,
                                0,0,0,4.3-9A20.55,20.55,0,0,1,40,39.06a22,22,0,0,1-10.86,6.33Z"
              />
            </Logo>
          </IconIcon>
          <Inner min={minVer}>
            <InnerBold>{ i18n.t("userContext.iconTable.corporateEmployees") }: </InnerBold>
            { i18n.t("userContext.iconTable.personalModulo") }
          </Inner>
        </IconRow>
        <IconRow min={minVer}>
          <IconIcon min={minVer}>
            <Logo viewBox="0 0 199.6 199.6" width={(minVer ? "29px" : "36px")}>
              <path style={{ fill: colors().platformPrimary }} d="M37.7,120.5L24.3,79.1h8.2L38.2,98c1.6,5.2,3,10,4,15h0.1c1.1-4.9,2.6-9.9,4.2-15l6-18.9h8l-14.3,41.3H37.7z"
              />
              <path style={{ fill: colors().platformPrimary }} d="M72.4,120.5L62.3,79.1h8l3.9,18.8c1,5,2,10.3,2.7,14.4h0.1c0.7-4.4,1.8-9.3,3-14.5l4.5-18.7h7.9l4.1,19
                                c1,4.9,1.9,9.5,2.5,14h0.1c0.7-4.5,1.7-9.3,2.8-14.3l4.3-18.6h7.7l-11.2,41.3h-8L90.5,101c-1-4.7-1.8-8.6-2.3-13.1H88
                                c-0.7,4.4-1.5,8.4-2.7,13.1l-4.8,19.4H72.4z"/>
              <path style={{ fill: colors().platformPrimary }} d="M119,79.1h23.9v6.2h-16.4v11.4h15.2v6.1h-15.2v17.6H119V79.1z"/>
              <path style={{ fill: colors().platformPrimary }} d="M150.1,112.2c2.4,1.4,6.1,2.6,10,2.6c4.8,0,7.5-2.3,7.5-5.6c0-3.1-2.1-5-7.3-6.9c-6.9-2.5-11.2-6.1-11.2-12
                                c0-6.8,5.6-11.9,14.7-11.9c4.5,0,7.7,1,9.9,2.1l-1.8,6.1c-1.5-0.8-4.4-2-8.3-2c-4.8,0-6.8,2.5-6.8,5c0,3.2,2.4,4.7,7.9,6.8
                                c7.2,2.7,10.6,6.3,10.6,12.3c0,6.7-5,12.4-15.6,12.4c-4.4,0-9-1.2-11.2-2.6L150.1,112.2z"/>
            </Logo>
          </IconIcon>
          <Inner min={minVer}>
            <InnerBold>{ i18n.t("userContext.iconTable.companyEmployees") }: </InnerBold>
            { i18n.t("userContext.iconTable.dkxNumber") }
          </Inner>
        </IconRow>
        <IconRow min={minVer}>
          <IconIcon min={minVer}>
            <Logo viewBox="0 0 22.02 16.03" width={(minVer ? "19px" : "22px")}>
              <polygon style={{ fill: colors().white }} points="21.02 3.31 21.02 3.31 14.77 8.07 21 14.32 21 14.32 14.77 8.07 21.02 3.31"/>
              <polygon style={{ fill: colors().white }} points="1.02 3.28 1 14.3 7.25 8.06 1.02 3.28"/>
              <polygon style={{ fill: colors().white }} points="11.01 10.95 8.05 8.68 1.71 15 20.3 15.03 13.97 8.68 11.01 10.95"/>
              <polygon style={{ fill: colors().white }} points="21 14.32 21 14.32 21.02 3.31 21.02 3.31 21 14.32"/>
              <polygon style={{ fill: colors().white }} points="1.02 2.02 11.01 9.7 21.02 2.05 21.02 1.03 1.02 1 1.02 2.02"/>
              <path style={{ fill: colors().platformPrimary }} d="M0,0,0,16l22,0L22,0ZM1,3.28,7.25,8.06,1,14.3ZM1.71,15,8.05,8.68,11,11l3-2.27L20.29,15ZM21,14.32h0L14.77,8.07,21,3.31h0ZM21,2.05,11,9.7,1,2V1L21,1Z"/>
            </Logo>
          </IconIcon>
          <Inner min={minVer}>
            <InnerBold>{ i18n.t("userContext.iconTable.otherUsers") }: </InnerBold>
            { i18n.t("userContext.iconTable.registeredEmail") }
          </Inner>
        </IconRow>
      </IconTableElement>);
  }
}
