import React from "react";
import styled from "styled-components";

import { breakpoints, colors } from "../../../../UIGlobals";

export const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 33px;
  margin-bottom: 25px;
  color: ${colors().semiDarkGray};
  text-align: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 115px 165px 175px;
  @media (${breakpoints().maxL}) {
    padding: 115px;
  }
  @media (${breakpoints().maxM}) {
    padding: 60px 0;
  }
  @media (${breakpoints().maxS}) {
    padding: 60px 0 0;
  }
`;

export const AnswerBoxTitle = styled.div`
  font-size: 20px;
  color: ${props => (props.greyed
  ? colors().gray10
  : props.blue
    ? colors().blue2
    : colors().semiDarkGray)};
  ${props => props.bold && "font-weight: bold"};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RenderCorrectComment = ({ comment }) =>
  Array.isArray(comment) ? comment.map(text => <p key={text}>{text}</p>) : <p>{comment}</p>;

export const AnswerBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 200px;
  height: 140px;
  border: 1px solid
  ${props => (props.wrongTheme
  ? colors().purple
  : props.greyed
    ? "rgba(122, 134, 150, 0.3)"
    : colors().blue3)};
  &:first-child {
    margin-right: 10px;
  }
  @media (${breakpoints().maxS}) {
    width: 150px;
  }
`;

export const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-bottom: 7px;
  background-color: ${props =>
  props.wrongTheme
    ? "rgba(168, 62, 108, 0.3)"
    : props.greyed
      ? "rgba(214, 218, 223, 0.3)"
      : props.correctTheme
        ? colors().blue2
        : "rgba(103, 144, 201, 0.3)"};
`;

export const WrongAnswerComment = styled.div`
  margin-top: 16px;
  font-size: 16px;
  line-height: 22px;
  color: ${colors().purple};
  height: 0;
  @media (${breakpoints().maxM}) {
    text-align: center;
  }
`;

export const ValidAnswerComment = styled(WrongAnswerComment)`
  color: ${colors().blue2};
`;

export const BorderedContainer = styled.div`
  border: 1px solid ${colors().blue2};
  width: 100%;
  padding: 30px 50px;
  font-size: 13px;
  line-height: 18px;
  color: ${colors().blue2};
  p:not(:first-of-type) {
    margin-top: 10px;
  }
  @media (${breakpoints().maxM}) {
    padding: 30px 20px;
  }
`;
