import styled from "styled-components";

import { Color, FontWeight, Media } from "../../../../theme";
import DesktopBg from "../../images/listEmptyBgDesktop.jpg";
import MobileBg from "../../images/listEmptyBgMobile.jpg";
import { ReactComponent as CalendarSvg } from "../../images/calendar.svg";

export const Container = styled.div`
  background-image: url(${MobileBg});
  aspect-ratio: 335/278;
  background-size: cover;
  background-position: center;
  position: relative;

  ${Media.additionalMobile`
    background-image: url(${DesktopBg});
    aspect-ratio: unset;
    height: 278px;
  `}

  ${Media.tablet`
    height: 360px;
  `}
`;

export const TextBox = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  position: absolute;
  bottom: 20px;
  left: 20px;

  ${Media.additionalMobile`
    top: 20px;
    bottom: unset;
  `}

  ${Media.tablet`
    gap: 20px;
    top: 35px;
    left: 35px;
  `}
`;

export const Icon = styled(CalendarSvg)`
  width: 52px;
  height: 52px;
  padding: 14px;
  border-radius: 2px;
  background-color: ${Color.gray6};

  path {
    stroke: ${Color.white};
  }

  ${Media.additionalMobile`
    background-color: ${Color.white};

    path {
      stroke: ${Color.deepGray};
    }
  `}

  ${Media.tabletWide`
    width: 68px;
    height: 68px;
    padding: 18px;
  `}
`;

export const Text = styled.div`
  font-size: 18px;
  line-height: normal;
  color: ${Color.white};

  ${Media.additionalMobile`
    color: ${Color.deepGray};
  `}

  ${Media.tabletWide`
    font-size: 24px;
  `}
`;

export const TextBold = styled.p`
  font-weight: ${FontWeight.Bold};
`;
