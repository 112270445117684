export const ItemType = {
  Content: "content",
  Individual: "individual_event",
  Event: "event",
  External: "external_event",
}

export const UriType = {
  Content: "content",
  Individual: "individual",
  Event: "event",
  External: "external"
};
