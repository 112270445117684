import React, { Component } from "react";
import i18n from "i18next";
import { withTranslation } from "react-i18next";

import { CookieName } from "../app/cookiePopup/cookiePopup.constants";
import { isCookieEnabled } from "../helpers/isCookieEnabled";

import IconTable from "./iconTable";

import {
  InnerBold,
  InfoPopupBoxContainer,
  InfoPopupInner,
  Paragraph,
  InfoPopupHeaderBlack,
  ParagraphContent,
  InfoPopupLine,
  CloseCross,
  Background,
} from "./infoPopups.styled";

class LoginPopupComponent extends Component {
  constructor() {
    super();
    this.state = {
      visible: localStorage.getItem("vwbfs-login-info") ? false : true,
    };
  }

  confirm(){
    if (isCookieEnabled(CookieName.Functional)) localStorage.setItem("vwbfs-login-info", true);
    this.setState({visible: false});
  }

  render(){
    return(
      <Background show={this.state.visible} >
        <InfoPopupBoxContainer show={this.state.visible}>
          <CloseCross onClick={() => {
            this.confirm();
          }}
          />
          <InfoPopupHeaderBlack>{ i18n.t("userContext.infoPopups.welcomeOnPlatform") }</InfoPopupHeaderBlack>
          <InfoPopupLine />
          <InfoPopupInner $flexFlow={"column"} $padding={"5px 0"} overflowY={"visible"} alignItems={"inherit"} onClick={e => e.stopPropagation()}>
            <Paragraph $padding={"0 0 25px 0"} >
              <ParagraphContent>{ i18n.t("userContext.infoPopups.asLoginUse") }:</ParagraphContent>
            </Paragraph>
            <IconTable />
            <Paragraph $padding={"20px 0 0 25px"} >
              <ParagraphContent>
                <InnerBold>{ i18n.t("userContext.infoPopups.password") }?</InnerBold>
              </ParagraphContent>
              <ParagraphContent>
                { i18n.t("userContext.infoPopups.itIsWithoutChanges") }: <a href="mailto:kontakt@vwbank-szkolenia.pl">kontakt@vwbank-szkolenia.pl</a>
              </ParagraphContent>
            </Paragraph>
          </InfoPopupInner>
          <InfoPopupLine />
        </InfoPopupBoxContainer>
      </Background>
    )
  }
}

const LoginPopup =  withTranslation()(LoginPopupComponent);

export {
  LoginPopup,
};
