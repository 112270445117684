import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { Loader } from "../../../../components/loader/loader.component";
import { useMediaListener } from "../../../../hooks/useMediaListener";
import { selectPassHistory } from "../../redux/idd.selectors";
import { IddActions } from "../../redux/idd.reducer";
import { PassItemDesktop } from "../passItemDesktop/passItemDesktop.component";
import { ListHeader, ListItems } from "../filesToDownload/filesToDownload.styled";
import { ListEmptyState } from "../listEmptyState/listEmptyState.component";

import {
  Container,
  HeaderItem,
  StyledPassItemMobile,
  StyledListPagination,
} from "./passHistory.styled";

export const PassHistory = ({ yearFilter }) => {
  const { t } = useTranslation();
  const { isTabletWide } = useMediaListener();
  const dispatch = useDispatch();
  const passHistory = useSelector(selectPassHistory);
  const [isFirstYearChange, setIsFirstYearChange] = useState(true);
  const items = passHistory?.elements;

  useEffect(() => {
    dispatch(IddActions.getPassHistory());
  }, []);

  useEffect(() => {
    if (yearFilter) {
      if (!isFirstYearChange) {
        dispatch(IddActions.getPassHistory(yearFilter, 1));
      } else {
        setIsFirstYearChange(false);
      }
    }
  }, [yearFilter]);

  const handlePageChange = (page) => {
    dispatch(IddActions.getPassHistory(yearFilter, page));
  };

  if (!items) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  if (items?.length === 0) {
    return (
      <Container>
        <ListEmptyState />
      </Container>
    );
  }

  return (
    <Container>
      {isTabletWide ? (
        <>
          <ListHeader>
            <HeaderItem>{t("idd.passHistory.material")}</HeaderItem>
            <HeaderItem>{t("idd.passHistory.time")}</HeaderItem>
            <HeaderItem>{t("idd.passHistory.type")}</HeaderItem>
            <HeaderItem>{t("idd.passHistory.passDate")}</HeaderItem>
            <HeaderItem></HeaderItem>
          </ListHeader>
          <ListItems>
            {items?.map(({ id, name, completedMinutes, type, image, completeTimestamp }) => (
              <PassItemDesktop
                key={id}
                id={id}
                title={name}
                minutes={completedMinutes}
                type={type}
                imageSrc={image}
                passTimestamp={completeTimestamp}
              />
            ))}
          </ListItems>
        </>
      ) : (
        items?.map(({ id, name, completedMinutes, type, image, completeTimestamp }) => (
          <StyledPassItemMobile
            key={id}
            id={id}
            title={name}
            minutes={completedMinutes}
            type={type}
            imageSrc={image}
            passTimestamp={completeTimestamp}
          />
        ))
      )}
      {passHistory?.maxPage > 1 && (
        <StyledListPagination
          currentPage={passHistory.currentPage}
          pagesTotal={passHistory.maxPage}
          onPrevClick={() => handlePageChange(passHistory.currentPage - 1)}
          onNextClick={() => handlePageChange(passHistory.currentPage + 1)}
          onPageClick={handlePageChange}
        />
      )}
    </Container>
  );
};
