import React from "react";

import { optionClickHandler } from "../quizHelpers";

import {
  Container,
  Title,
  TilesRow,
  TextTileOption,
  WrongAnswerComment,
  ValidAnswerComment,
} from "./fourAnswersQuiz.styled";

export class FourAnswersQuiz extends React.Component {
  state = {
    wrongAnswerComment: "",
    correctAnswerComment: "",
    clickedIndex: null
  };

  onOptionClick = (isCorrect, index) => {
    const { data, setDisabledButton } = this.props;
    const { correctText, incorrectText } = data;
    this.setState(optionClickHandler(isCorrect, correctText, incorrectText, setDisabledButton, index));
  }

  render() {
    const { text, answers } = this.props.data;
    const { correctAnswerComment, wrongAnswerComment, clickedIndex } = this.state;
    const correctAnswerClicked = !!correctAnswerComment.length;
    const wrongAnswerClicked = !!wrongAnswerComment.length;

    return (
      <Container>
        <Title>{text}</Title>
        <TilesRow>
          <TextTileOption
            onClick={() => this.onOptionClick(answers[0].isCorrect, 0)}
            correctTheme={clickedIndex === 0 && correctAnswerClicked}
            wrongTheme={clickedIndex === 0 && wrongAnswerClicked}
            greyed={clickedIndex !== 0 && correctAnswerClicked}
            answer={answers[0]}
          />
          <TextTileOption
            onClick={() => this.onOptionClick(answers[1].isCorrect, 1)}
            correctTheme={clickedIndex === 1 && correctAnswerClicked}
            wrongTheme={clickedIndex === 1 && wrongAnswerClicked}
            greyed={clickedIndex !== 1 && correctAnswerClicked}
            answer={answers[1]}
          />
        </TilesRow>
        <TilesRow>
          <TextTileOption
            onClick={() => this.onOptionClick(answers[2].isCorrect, 2)}
            correctTheme={clickedIndex === 2 && correctAnswerClicked}
            wrongTheme={clickedIndex === 2 && wrongAnswerClicked}
            greyed={clickedIndex !== 2 && correctAnswerClicked}
            answer={answers[2]}
          />
          <TextTileOption
            onClick={() => this.onOptionClick(answers[3].isCorrect, 3)}
            correctTheme={clickedIndex === 3 && correctAnswerClicked}
            wrongTheme={clickedIndex === 3 && wrongAnswerClicked}
            greyed={clickedIndex !== 3 && correctAnswerClicked}
            answer={answers[3]}
          />
        </TilesRow>
        {wrongAnswerClicked && <WrongAnswerComment>{wrongAnswerComment}</WrongAnswerComment>}
        {correctAnswerClicked && <ValidAnswerComment>{correctAnswerComment}</ValidAnswerComment>}
      </Container>
    );
  }
}
