import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import { Loader } from "../../../../components/loader/loader.component";
import { simpleDateFromTimestamp } from "../../../../helpers/date";
import { selectCertificateInfo } from "../../redux/idd.selectors";
import { SectionTitle } from "../filesToDownload/filesToDownload.styled";

import {
  Container,
  Wrapper,
  StyledIcon,
  Content,
  Title,
  Text,
  Date,
  ArrowIcon,
} from "./pathsBox.styled";

export const PathsBox = ({ className }) => {
  const { t } = useTranslation();
  const certificateInfo = useSelector(selectCertificateInfo);
  const certificateId = certificateInfo?.id;
  const fromDateTimestamp = certificateInfo?.validateFrom || null;
  const toDateTimestamp = certificateInfo?.validateTo || null;
  const isError = dayjs.unix(fromDateTimestamp).year() !== dayjs().year();
  const passDate = simpleDateFromTimestamp(isError ? fromDateTimestamp : toDateTimestamp);

  return (
    <Container className={className}>
      <SectionTitle>{t("idd.pathsBox.certificatePaths")}</SectionTitle>
      <Wrapper $isDisabled={isError} to={certificateId ? `/certyfikaty/${certificateId}` : ""}>
        <StyledIcon />
        {certificateId ? (
          <>
            <Content>
              <Title>{certificateInfo.name}</Title>
              <Text>{certificateInfo.description}</Text>
              <Date $isError={isError}>
                {t(isError ? "idd.pathsBox.availableFrom" : "idd.pathsBox.passUntil")} {passDate}
              </Date>
            </Content>
            {!isError && <ArrowIcon />}
          </>
        ) : (
          <Loader />
        )}
      </Wrapper>
    </Container>
  );
};
