import React, { Component } from "react";
import i18n from "i18next";

import { colors } from "../../../../UIGlobals";

import { Icon } from "../../../../UIElements/icons";
import { Title, Container, AnswerBoxesContainer, AnswerBox, AnswerBoxTitle } from "./agreementQuiz.styled";
import { AgreementType } from "./agreementQuiz.constants";

export class AgreementQuiz extends Component {
  state = {
    clickedIndex: null
  };

  renderTitle = title =>
    Array.isArray(title) ? title.map(text => <Title key={text}>{text}</Title>) : <Title>{title}</Title>;

  onOptionClick = (isCorrect, index) => {
    const { setDisabledButton } = this.props;

    this.setState({ clickedIndex: index });
    setDisabledButton(isCorrect);
  };

  getIconColor = ({ whenWrong, whenGreyed }) => whenWrong
    ? colors().purple
    : whenGreyed
      ? "rgba(122, 134, 150, 0.3)"
      : colors().blue2;

  handleHorizontalClick = () => this.onOptionClick(this.props.data.correctAnswer === AgreementType.Horizontal, 0);
  handleVerticalClick = () => this.onOptionClick(this.props.data.correctAnswer === AgreementType.Vertical, 1);

  render() {
    const { text, correctAnswer } = this.props.data;
    const { clickedIndex } = this.state;

    const wrongHorizontalClicked = clickedIndex === 0 && correctAnswer !== AgreementType.Horizontal;
    const correctHorizontalClicked = clickedIndex === 0 && correctAnswer === AgreementType.Horizontal;
    const wrongVerticalClicked = clickedIndex === 1 && correctAnswer !== AgreementType.Vertical;
    const correctVerticalClicked = clickedIndex === 1 && correctAnswer === AgreementType.Vertical;

    return (
      <Container>
        <div>
          {this.renderTitle(text)}
          <AnswerBoxesContainer>
            <AnswerBox
              greyed={correctVerticalClicked}
              wrongTheme={wrongHorizontalClicked}
              onClick={this.handleHorizontalClick}
            >
              <Icon
                icon="connectedDotsVertical"
                size="40"
                color={this.getIconColor(wrongHorizontalClicked, correctVerticalClicked)}
                cursor="hover"
              />
              <AnswerBoxTitle
                greyed={correctVerticalClicked}
                bold={wrongHorizontalClicked || correctHorizontalClicked}
                blue={correctHorizontalClicked}
              >
                {i18n.t("agreementQuiz.agreementQuiz.horizontalAgreement")}
              </AnswerBoxTitle>
            </AnswerBox>
            <AnswerBox
              greyed={correctHorizontalClicked}
              wrongTheme={wrongVerticalClicked}
              onClick={this.handleVerticalClick}
            >
              <Icon
                icon="connectedDotsHorizontal"
                size="40"
                color={this.getIconColor(wrongVerticalClicked, correctHorizontalClicked)}
                cursor="hover"
              />
              <AnswerBoxTitle
                greyed={correctHorizontalClicked}
                bold={wrongVerticalClicked || correctVerticalClicked}
                blue={correctVerticalClicked}
              >
                {i18n.t("agreementQuiz.agreementQuiz.verticalAgreement")}
              </AnswerBoxTitle>
            </AnswerBox>
          </AnswerBoxesContainer>
        </div>
      </Container>
    );
  }
}
