import React from "react";
import styled from "styled-components";

import { colors } from "../UIGlobals";

const Avatar = styled.div`
  font-size: 1.4rem;
  width: 44px;
  height: 44px;
  flex: 0 0 44px;
  border-radius: 50%;
  background: ${props => (props.$background ? colors()[props.$background] : "#a0a0a0")};
  color: ${colors().white};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  margin-right: 14px;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
    background: ${colors().white};
    height: auto;
    overflow: hidden
  }
`;

const RankAvatar = ({ rankItem }) => {
  return (
    <SmallAvatar $background="white" $border="dirtyWhite">
      {rankItem.avatar ? (
        <img src={rankItem.avatar} alt="avatar" />
      ) : (
        rankItem.name
          .split(" ")
          .map(string => string.substr(0, 1).toUpperCase())
          .slice(0, 2)
          .join("")
      )}
    </SmallAvatar>
  );
};

const SmallAvatar = styled(Avatar)`
  width: 34px;
  height: 34px;
  flex: 0 0 34px;
  font-size: 1.1rem;
  color: ${props =>
  props.$background === "white"
    ? colors().deepGray
    : props.$background === "brandBasic"
      ? colors().white
      : colors().white};
  box-shadow: inset 0 0 0 1px ${props => (props.$border ? colors()[props.$border] : "transparent")};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : "800")};
`;

const BigAvatar = styled(Avatar)`
  font-size: 2rem;
  width: 80px;
  height: 80px;
  flex: 0 0 80px;
  background: ${colors().white};
  border: 1px solid ${colors().veryLightGreen};
  color: ${colors().black};
`;

export {
  Avatar,
  SmallAvatar,
  BigAvatar,
  RankAvatar
};
