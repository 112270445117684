import styled, { css } from "styled-components";
import { prop } from "ramda";

import { Button } from "../../../../components/button/button.component";
import { styleWhenTrue } from "../../../../helpers/rendering";
import { ReactComponent as DownloadSvg } from "../../../../images/download.svg";
import { Color, ContentMaxWidth, FontWeight, Media, Separator } from "../../../../theme";
import { InsuranceCompanyBox } from "../insuranceCompanyBox/insuranceCompanyBox.component";
import { PathsBox } from "../pathsBox/pathsBox.component";
import { DropdownInput } from "../../../../components/dropdownInput/dropdownInput.component";

export const Container = styled.div`
  background-color: ${Color.almostWhite};
  padding: 60px 20px;
`;

export const Wrapper = styled.div`
  max-width: ${ContentMaxWidth};
  margin: 0 auto;
`;

export const SectionTitle = styled.p`
  color: ${Color.deepGray};
  font-size: 18px;
  font-weight: ${FontWeight.Bold};
  line-height: normal;
  margin-bottom: 25px;

  ${Media.desktop`
    margin-bottom: 30px;
  `}
`;

export const TabsRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  gap: 15px;

  ${Media.tablet`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `}
`;

export const Tabs = styled.div`
  display: flex;
  gap: 30px;
`;

export const Tab = styled.button`
  font-size: 14px;
  font-weight: ${FontWeight.SemiBold};
  color: ${Color.gray9};
  line-height: normal;
  padding: 0 0 13px;
  border: none;
  background-color: ${Color.transparent};
  border-bottom: 2px solid ${Color.transparent};
  width: 50%;

  ${styleWhenTrue(prop("$isActive"), css`
    color: ${() => Color.brandBasic};
    border-bottom: 2px solid ${() => Color.brandBasic};
  `)}

  ${Media.tablet`
    width: auto;
    padding: 2px 0 13px;
  `}
`;

export const YearFilter = styled.div``;

export const ListHeader = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  background-color: ${Color.white};
  padding: 14px 35px;
`;

export const headerItemStyles = css`
  font-size: 10px;
  color: ${Color.gray9};
  font-weight: ${FontWeight.Bolder};
  line-height: normal;
  letter-spacing: 0.923px;
  text-transform: uppercase;
  flex: 1;
`;

export const ListItems = styled.div`
  padding: 10px 35px;
  background-color: ${Color.white};
  margin-top: 2px;
`;

export const ListMobileProp = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  color: ${Color.gray9};

  & + & {
    border-top: 1px solid ${Color.dirtyWhite};
  }
`;

export const ListMobilePropTitle = styled.p`
  font-size: 10px;
  font-weight: ${FontWeight.Bolder};
  letter-spacing: 0.923px;
  text-transform: uppercase;
  line-height: normal;
`;

export const ListMobilePropText = styled.p`
  font-size: 12px;
  line-height: normal;

  ${styleWhenTrue(prop("$withError"), css`
    color: ${Color.error};
  `)}
`;

export const ListMobileDownloadButton = styled(Button)`
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  margin-top: 8px;
  justify-content: center;
`;

export const ListMobileDownloadIcon = styled(DownloadSvg)`
  width: 11px;
  height: 11px;

  ${styleWhenTrue(prop("$isDisabled"), css`
    fill: ${Color.dirtyWhite};
  `)}
`;

export const listDesktopRowStyles = css`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${Color.dirtyWhite};
  gap: 10px;

  &:last-of-type {
    border-bottom: none;
  }
`;

export const ListDesktopDownloadButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const ListDesktopDownloadIcon = styled(DownloadSvg)`
  width: 11px;
  height: 11px;
  fill: ${Color.white};
`;

export const StyledSeparator = styled(Separator)`
  margin-top: 60px;
  margin-bottom: 60px;
  background: ${Color.gray1};

  ${Media.tabletWide`
    display: none;
  `}
`;

export const BottomSections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;

  ${Media.tabletWide`
    flex-direction: row;
    gap: 20px;
    margin-top: 60px;
  `}
`;

export const StyledInsuranceCompanyBox = styled(InsuranceCompanyBox)`
  flex: 1;
`;

export const StyledPathsBox = styled(PathsBox)`
  flex: 1;
`;

export const StyledDropdownInput = styled(DropdownInput)`
  margin-bottom: 0;
  min-width: 140px;
`;
