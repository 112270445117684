import React from "react";

import {
  Container,
  Pages,
  Page,
  PrevButton,
  NextButton,
  Arrow,
} from "./listPagination.styled";

export const ListPagination = ({
  className,
  pagesTotal,
  currentPage,
  onPrevClick,
  onNextClick,
  onPageClick,
}) => {
  const pages = Array.from({ length: pagesTotal }, (_, i) => i + 1);

  return (
    <Container className={className}>
      <PrevButton disabled={currentPage === 1} onClick={onPrevClick}>
        <Arrow />
      </PrevButton>
      <Pages>
        {pages.map((page) => (
          <Page
            key={page}
            $isActive={page === currentPage}
            disabled={page === currentPage}
            onClick={() => onPageClick(page)}
          >
            {page}
          </Page>
        ))}
      </Pages>
      <NextButton disabled={currentPage === pagesTotal} onClick={onNextClick}>
        <Arrow />
      </NextButton>
    </Container>
  );
};
