import styled, { css } from "styled-components";

import { Color, ContentMaxWidth, FontWeight, Media } from "../../../../theme";
import BgDesktop from "../../images/headerBgDesktop.jpg";
import BgMobile from "../../images/headerBgMobile.jpg";
import { ReactComponent as CoinsSvg } from "../../images/coins.svg";
import { ReactComponent as ClockSvg } from "../../images/clock.svg";

export const Container = styled.div`
  color: ${Color.deepGray};
  background-image: url(${BgMobile});
  background-size: cover;
  background-size: 100% 100%;

  ${Media.tablet`
    background-image: url(${BgDesktop});
    aspect-ratio: 152 / 52;
  `}
`;

export const MobileImg = styled.img`
  display: block;
  width: 100%;

  ${Media.tablet`
    display: none;
  `}
`;

export const Wrapper = styled.div`
  max-width: calc(${ContentMaxWidth} + 40px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 40px 20px;

  ${Media.tablet`
    height: 100%;
  `}

  ${Media.desktop`
    padding: 80px 20px;
  `}
`;

export const Title = styled.p`
  font-size: 28px;
  font-weight: ${FontWeight.Light};
  white-space: pre-line;
  line-height: normal;

  ${Media.desktop`
    font-size: 42px;
  `}
`;

export const Stats = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${Media.tablet`
    flex-direction: row;
    margin-top: auto;
    gap: 60px;
  `}
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  ${Media.desktop`
    gap: 20px;
  `}
`;

export const Icon = styled.div`
  width: 52px;
  height: 52px;
  background-color: ${Color.white};
  display: flex;
  align-items: center;
  justify-content: center;

  ${Media.desktop`
    width: 68px;
    height: 68px;
  `}
`;

const svgIconStyles = css`
  width: 32px;
  height: 32px;

  ${Media.desktop`
    width: 40px;
    height: 40px;
  `}
`;

export const CoinsIcon = styled(CoinsSvg)`
  ${svgIconStyles}
`;

export const ClockIcon = styled(ClockSvg)`
  ${svgIconStyles}
`;

export const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ItemText = styled.p`
  font-size: 24px;
  line-height: 1;

  ${Media.desktop`
    font-size: 36px;
  `}
`;

export const ItemTitle = styled.p`
  font-size: 10px;
  line-height: 2;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  font-weight: ${FontWeight.SemiBold};
  color: ${Color.error};
`;

export const ItemTextSmall = styled.span`
  ${Media.desktop`
    font-size: 24px;
  `}
`;
