import React from "react";
import styled from "styled-components";

import { breakpoints, colors } from "../../../../UIGlobals";

import { Icon } from "../../../../UIElements/icons";

export const Container = styled.div`
  padding: 115px 130px 200px;
  @media (${breakpoints().maxL}) {
    padding: 60px 0 0;
  }
`;

export const Title = styled.div`
  font-size: 20px;
  line-height: 33px;
  margin-bottom: 30px;
`;

const TileText = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: ${props => (
  props.greyed
    ? colors().gray10
    : props.correctTheme
      ? colors().blue2
      : colors().semiDarkGray)};
  ${props => props.correctTheme && "font-weight: 600"};
  @media (${breakpoints().maxS}) {
    text-align: center;
  }
`;

export const TilesRow = styled.div`
  display: flex;
  flex-direction: row;
  div:first-child {
    margin-right: 10px;
  }
`;

const BorderedTile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  width: 100%;
  padding: 25px 27px;
  margin-bottom: 10px;
  border: 1px solid ${props => (
  props.wrongTheme
    ? colors().purple
    : props.greyed
      ? "rgb(122, 134, 150, 0.2)"
      : colors().blue3)};
  i {
    margin-right: 27px;
  }
  @media (${breakpoints().maxS}) {
    flex-direction: column;
    i {
      margin-right: 0;
    }
  }
`;

export const WrongAnswerComment = styled.div`
  font-size: 16px;
  margin-top: 16px;
  line-height: 22px;
  color: ${colors().purple};
  height: 0;
`;

export const ValidAnswerComment = styled(WrongAnswerComment)`
  color: ${colors().blue2};
`;

export const TextTileOption = ({
  onClick,
  answer,
  wrongTheme,
  correctTheme,
  greyed
}) => {
  const { text, icon } = answer;
  const tileIconColor =
    greyed
      ? colors().gray11
      : wrongTheme
        ? colors().purple
        : colors().blue2;
  return (
    <BorderedTile greyed={greyed} wrongTheme={wrongTheme} correctTheme={correctTheme} onClick={onClick}>
      <Icon
        cursor="hover"
        icon={icon}
        size={40}
        color={tileIconColor}
      />
      <TileText greyed={greyed} correctTheme={correctTheme}>
        {text}
      </TileText>
    </BorderedTile>
  );
};
