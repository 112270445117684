import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Loader } from "../../../../components/loader/loader.component";
import AllianzLogo from "../../images/allianz.svg";
import CardifLogo from "../../images/cardif.svg";
import HestiaLogo from "../../images/hestia.svg";
import VersicherungLogo from "../../images/versicherung.svg";
import WartaLogo from "../../images/warta.svg";
import { selectInsuranceCompanies } from "../../redux/idd.selectors";
import { SectionTitle } from "../filesToDownload/filesToDownload.styled";

import {
  Container,
  Content,
  CompanyList,
  CompanyLogo,
  StyledIcon,
} from "./insuranceCompanyBox.styled";
import { InsuranceCompany } from "./insuranceCompanyBox.constants";

export const InsuranceCompanyBox = ({ className }) => {
  const { t } = useTranslation();
  const companies = useSelector(selectInsuranceCompanies);

  const getCompanyLogo = (company) => {
    switch (company) {
    case InsuranceCompany.Allianz: return AllianzLogo;
    case InsuranceCompany.Cardif: return CardifLogo;
    case InsuranceCompany.Hestia: return HestiaLogo;
    case InsuranceCompany.Versicherung: return VersicherungLogo;
    case InsuranceCompany.Warta: return WartaLogo;
    }
  };

  if (!companies?.length) return null;

  return (
    <Container className={className}>
      <SectionTitle>{t("idd.insuranceCompanyBox.insuranceCompanies")}</SectionTitle>
      <Content>
        <StyledIcon />
        {companies ? (
          <CompanyList>
            {companies.map(({ id, name }) => (
              <CompanyLogo key={id} src={getCompanyLogo(name)} />
            ))}
          </CompanyList>
        ) : (
          <Loader />
        )}
      </Content>
    </Container>
  );
};
