export const optionClickHandler = (
  isCorrect,
  correct_text,
  incorrect_text,
  setDisabledButton,
  index = -1
) => {
  setDisabledButton(isCorrect);
  const state = {
    correctAnswerComment: isCorrect ? correct_text : "",
    wrongAnswerComment: isCorrect ? "" : incorrect_text,
  }
  if (index > -1) Object.assign(state, {clickedIndex: index});
  return state;
};
