import React, { useState } from "react";

import {
  Container,
  Title,
  TextTileOption,
  WrongAnswerComment,
  ValidAnswerComment,
} from "./threeAnswersQuiz.styled";

export const ThreeAnswersQuiz = ({ data, setDisabledButton }) => {
  const [wrongAnswerComment, setWrongAnswerComment] = useState("");
  const [correctAnswerComment, setCorrectAnswerComment] = useState("");
  const [clickedIndex, setClickedIndex] = useState(null);
  const { text, answers, incorrectText, correctText } = data;

  const onOptionClick = (isCorrect, index) => {
    setClickedIndex(index);
    setDisabledButton(isCorrect);
    setWrongAnswerComment(isCorrect ? "" : incorrectText);
    setCorrectAnswerComment(isCorrect ? correctText : "");
  };

  return (
    <Container>
      <Title>{text}:</Title>
      {answers.map((answer, i) => (
        <TextTileOption
          onClick={() => onOptionClick(answer.isCorrect, i)}
          wrongTheme={clickedIndex === i && !answer.isCorrect}
          correctTheme={clickedIndex === i && answer.isCorrect}
          text={answer.text}
          key={answer.text}
        />
      ))}
      <WrongAnswerComment>{wrongAnswerComment}</WrongAnswerComment>
      <ValidAnswerComment>{correctAnswerComment}</ValidAnswerComment>
    </Container>
  );
};
