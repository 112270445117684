import { createSelector } from "reselect";
import { prop } from "ramda";

export const selectIddDomain = prop("idd");

export const selectKnfHistory = createSelector(
  selectIddDomain, prop("knfHistories"),
);

export const selectStats = createSelector(
  selectIddDomain, prop("stats"),
);

export const selectInsuranceCompanies = createSelector(
  selectIddDomain, prop("insurances"),
);

export const selectCertificateInfo = createSelector(
  selectIddDomain, prop("certificateInfo"),
);

export const selectContents = createSelector(
  selectIddDomain, prop("contents"),
);

export const selectPassHistory = createSelector(
  selectIddDomain, prop("passHistory"),
);

export const selectPassHistoryFilters = createSelector(
  selectPassHistory, prop("filters"),
);

export const selectCertificatesHistory = createSelector(
  selectIddDomain, prop("certificatesHistory"),
);
