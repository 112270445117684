import React from "react";

import { optionClickHandler } from "../quizHelpers";

import { Container, Title, TextTileOption, WrongAnswerComment, ValidAnswerComment } from "./twoTitledAnswersQuiz.styled";

export class TwoTitledAnswersQuiz extends React.Component {
  state = {
    wrongAnswerComment: "",
    correctAnswerComment: ""
  };

  onOptionClick = (isCorrect, index) => {
    const { data, setDisabledButton } = this.props;
    const { correctText, incorrectText } = data;
    this.setState(optionClickHandler(isCorrect, correctText, incorrectText, setDisabledButton, index));
  }

  handleFirstAnswer = () => this.onOptionClick(this.props.data.answers[0].isCorrect);
  handleSecondAnswer = () => this.onOptionClick(this.props.data.answers[1].isCorrect);

  render() {
    const { text, answers } = this.props.data;
    const { correctAnswerComment, wrongAnswerComment } = this.state;
    const correctAnswerClicked = correctAnswerComment.length;
    const wrongAnswerClicked = wrongAnswerComment.length;

    return (
      <Container>
        <Title>{text}</Title>
        <TextTileOption
          onClick={this.handleFirstAnswer}
          wrongClicked={wrongAnswerClicked}
          correctClicked={correctAnswerClicked}
          answer={answers[0]}
        />
        <TextTileOption
          onClick={this.handleSecondAnswer}
          wrongClicked={wrongAnswerClicked}
          correctClicked={correctAnswerClicked}
          answer={answers[1]}
        />
        <WrongAnswerComment>{wrongAnswerComment}</WrongAnswerComment>
        <ValidAnswerComment>{correctAnswerComment}</ValidAnswerComment>
      </Container>
    );
  }
}
