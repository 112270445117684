import { all, put, takeLatest } from "redux-saga/effects";

import * as iddApi from "../services/api";

import { IddTypes, IddActions } from "./idd.reducer";

function* getInitialData() {
  const { data } = yield iddApi.getInitialData();

  if (data) {
    yield put(IddActions.getInitialDataSuccess(data));
  }
}

function* getContents() {
  const { data } = yield iddApi.getContents();

  if (data) {
    yield put(IddActions.getContentsSuccess(data));
  }
}

function* getPassHistory({ year, page }) {
  const { data } = yield iddApi.getPassHistory(year, page);

  if (data) {
    yield put(IddActions.getPassHistorySuccess(data));
  }
}

function* getCertificatesHistory({ page }) {
  const { data } = yield iddApi.getCertificatesHistory(page);

  if (data) {
    yield put(IddActions.getCertificatesHistorySuccess(data));
  }
}

export function* watchIdd() {
  yield all([
    yield takeLatest(IddTypes.GET_INITIAL_DATA, getInitialData),
    yield takeLatest(IddTypes.GET_CONTENTS, getContents),
    yield takeLatest(IddTypes.GET_PASS_HISTORY, getPassHistory),
    yield takeLatest(IddTypes.GET_CERTIFICATES_HISTORY, getCertificatesHistory),
  ]);
}
