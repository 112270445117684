import React from "react";

import {
  TextContainer,
  Header,
  TextPart,
  OrderedList,
  ListElement,
} from "../regulations.styled";

export const RegulationsContent = () => (
  <TextContainer>
    <TextPart>
      <Header>§ 1. Postanowienia ogólne</Header>
      <OrderedList>
        <ListElement>
          PRZEDMIOT, CEL<br />
          1) Niniejszy regulamin określa zasady świadczenia usług drogą elektroniczną serwisu służącego m.in. do:<br />
          a) przedstawienia i podnoszenia świadomości Użytkowników, w szczególności o&nbsp;obowiązujących w grupie Volkswagen Financial Services (Volkswagen Bank GmbH Sp. z.o.o. Oddział w Polsce, Volkswagen Financial Services Polska Sp. z o.o. oraz Volkswagen Serwis Ubezpieczeniowy Sp. z o.o.) regulacjach prawnych, regulacjach wewnętrznych (w tym procedurach, instrukcjach, zasadach, produktach),<br />
          b) podnoszenia kwalifikacji zawodowych Użytkowników poprzez organizowanie i&nbsp;przeprowadzanie szkoleń, a także wydanie stosownych certyfikatów/zaświadczeń (tam, gdzie dotyczy), przeprowadzania kontroli i ewaluacji wiedzy, zarządzanie egzaminami/testami;<br />
          c) przeprowadzania identyfikacji potrzeb szkoleniowo-rozwojowych w celu poprawy skuteczności przeprowadzanych szkoleń;<br />
          d) integrację społeczności Użytkowników Serwisu oraz ułatwiania kontaktów pomiędzy nimi;<br />
          2) Usługa polega  na bezpłatnym  udostępnieniu Użytkownikom Serwisu treści publikowanych w Serwisie i udostępnieniu funkcjonalności na potrzeby realizacji celów wskazanych powyżej.
        </ListElement>
        <ListElement>
          DEFINICJE<br />
          1) Właściciel Serwisu - właścicielem Serwisu jest Volkswagen Bank GmbH Sp. z.o.o. Oddział w Polsce („VWB”), Volkswagen Financial Services Polska Sp. z o.o. („VW FSP”) oraz Volkswagen Serwis Ubezpieczeniowy Sp. z o.o. - wszystkie z siedzibą w Warszawie (00-124), Rondo ONZ 1.<br />
          2) Dealer – podmioty sieci dealerskiej, które zawarły stosowne porozumienia o&nbsp;współpracy z Volkswagen Bank GmbH Sp. z.o.o. Oddział w Polsce lub Volkswagen Financial Services Polska Sp. z o.o. (uprzednio Volkswagen Leasing GmbH Sp. z o.o. Oddział w Polsce) lub Volkswagen Serwis Ubezpieczeniowy Sp. z o.o.
          3) MAN T&B – importer generalny, który podpisał stosowne porozumienia o&nbsp;współpracy z Volkswagen Bank GmbH Sp. z.o.o. Oddział w Polsce lub Volkswagen Financial Services Polska Sp. z o.o. (uprzednio Volkswagen Leasing GmbH Sp. z o.o. Oddział w Polsce) lub Volkswagen Serwis Ubezpieczeniowy Sp. z o.o.  Pracownicy/współpracownicy MAN T&B (niezależnie od podstawy prawnej zatrudnienia) nazywani są dalej ”pracownikami MAN T&B”.< br />
          4) Operator - Operatorem Serwisu jest MUG Software sp. z o.o. Feliksa Nowowiejskiego 3/5 61-731 Poznań, o numerze statystycznym REGON 300564590, numerze identyfikacji podatkowej (NIP) 7811799986<br />
          5) OFWCA – osoba fizyczna wykonująca czynności agencyjne na rzecz dystrybutora ubezpieczeń zgodnie z ustawa o dystrybucji ubezpieczeń, będąca jednocześnie Pracownikiem/Współpracownikiem, Sprzedawcą lub pracownikiem MAN T&B.<br />
          6) Regulamin – niniejszy regulamin Serwisu.<br />
          7) Polityka - Polityka Prywatności oraz wykorzystywania plików cookies.<br />
          8) Pracownik/Współpracownik – osoba fizyczna zatrudniona odpowiednio w Volkswagen Bank GmbH Sp. z.o.o. Oddział w Polsce, Volkswagen Financial Services Polska Sp. z o.o.  lub Volkswagen Serwis Ubezpieczeniowy Sp. z o.o. na podstawie przepisów prawa pracy lub na podstawie umowy cywilnoprawnej.
          9) Serwis – platforma służąca do realizacji celów określonych Regulaminem, nazywana również Platformą Szkoleniową lub Platformą Elearningową,<br />
          10) Sprzedawca – osoba fizyczna będąca pracownikiem, współpracownikiem Dealera (niezależnie od podstawy zatrudnienia i pełnionej funkcji),<br />
          11) Użytkownik - zarejestrowany i zweryfikowany użytkownik, upoważniony przez Właściciela Serwisu do korzystania z treści publikowanych w Serwisie, będący odpowiednio Pracownikiem/Współpracownikiem, Sprzedawcą, pracownikiem MAN T&B.
        </ListElement>
        <ListElement>
          WYMAGANIA<br />
          1) Właściciel Serwisu zastrzega następujące wymagania techniczne konieczne do prawidłowego działania Serwisu:<br />
          a) przeglądarka Chrome, Safari, Firefox, Edge, Opera w najnowszej wersji,<br />
          b) włączona obsługa JavaScript,<br />
          c) włączona obsługa Cookie,<br />
          d) minimalna rozdzielczość 1024x768.
        </ListElement>
        <ListElement>
          Użytkownik może korzystać z Serwisu z wykorzystaniem przeglądarki internetowej pod adresem wskazanym poniżej lub za pośrednictwem dedykowanej aplikacji – jeżeli Właściciel Serwisu udostępnił taką możliwość.
        </ListElement>
        <ListElement>
          Serwis dostępny jest pod adresem:<br />
          <a href="https://www.vwbank-szkolenia.pl">https://www.vwbank-szkolenia.pl</a>
        </ListElement>
        <ListElement>
          Serwis dostępny jest pod adresem:<br />
          <a href="https://www.vwbank-szkolenia.pl">https://www.vwbank-szkolenia.pl</a>
        </ListElement>
        <ListElement>
          Formularz rejestracyjny dostępny jest pod adresem:<br />
          <a href="https://www.vwbank-szkolenia.pl/logowanie#rejestracja">https://www.vwbank-szkolenia.pl/logowanie#rejestracja</a>
        </ListElement>
      </OrderedList>
    </TextPart>
    <TextPart>
      <Header>§ 2. Rejestracja i pierwsze logowanie Użytkownika w Serwisie</Header>
      <OrderedList>
        <ListElement>
          W pełni aktywnym Użytkownikiem może być osoba, która łącznie spełniła poniższe warunki<br />
          1) posiada uprawnienia do korzystania z Serwisu,<br />
          2) zarejestrowała się w Serwisie, w tym zaakceptowała Zasady Korzystania z Platformy Szkoleniowej oraz złożyła przy rejestracji wymagane oświadczenia,<br />
          3) została zweryfikowana przez Operatora serwisu i<br />
          4) aktywowała konto.
        </ListElement>
        <ListElement>
          Rejestracji użytkownika dokonuje Operator serwisu na podstawie wypełnionego przez Użytkownika formularza rejestrowego.
        </ListElement>
        <ListElement>
          Użytkownik zobowiązany jest do:<br />
          1) do zapoznania się z treścią Zasad korzystania z Platformy Szkoleniowej (Regulamin Serwisu, Polityka prywatności i wykorzystywania plików cookies). Jednocześnie Użytkownik oświadcza, iż ww. dokument został przez niego przeczytany, jego treść i skutki prawne są dla niego zrozumiałe i zostały w takiej postaci w całości zaakceptowane, na dowód czego zaakceptował za pośrednictwem formularza jego treść.<br />
          2) zachowania w poufności wszelkich informacji zawartych na Platformie Szkoleniowej, które uzyskał podczas korzystania z niej,<br />
          3) wykorzystania informacji zawartych w Serwisie, wyłącznie w celu realizacji obowiązków jako Pracownik/Współpracownik, Sprzedawca, pracownik MAN T&B,<br />
          4) nie udostępniania tych informacji, o których mowa w pkt. 2, podmiotom nieuprawnionym do ich otrzymania.
        </ListElement>
        <ListElement>
          Wyrażenie wskazanej w Serwisie zgody na wykorzystanie wizerunku jest dobrowolne, a brak jej wyrażenia nie wpływa na proces rejestracji, czy też dalsze uprawnienie do korzystania z Serwisu przez Użytkownika. Brak wyrażenia zgód skutkować będzie jedynie brakiem możliwości wgrania zdjęcia do Serwisu.
        </ListElement>
        <ListElement>
          Użytkownik zobowiązuje się, że na umieszczonych przez siebie w Serwisie zdjęciach nie będą widoczne wizerunki osób trzecich, ani treści, które mogłyby naruszyć obowiązujące przepisy prawa oraz że umieszczane zdjęcia nie będą prezentowały treści wulgarnych, obraźliwych, sprzecznych z&nbsp;zasadami współżycia społecznego.
        </ListElement>
        <ListElement>
          Umieszczając zdjęcie w Serwisie Użytkownik oświadcza, że jest jego twórcą w rozumieniu ustawy o Prawach autorskich i prawach zależnych lub posiada nieograniczone prawo do jego dysponowania, w tym w zakresie potrzebnym do jego umieszczenia w Serwisie.
        </ListElement>
        <ListElement>
          Po weryfikacji użytkownika, na wskazany w formularzu zgłoszeniowym adres poczty elektronicznej Użytkownika wysyłany jest link aktywacyjny konto w Serwisie.
        </ListElement>
        <ListElement>
          Przy pierwszym logowaniu system będzie wymagał od Użytkownika ustawienie własnego hasła do logowania.
        </ListElement>
        <ListElement>
          Przy każdym kolejnym logowaniu Użytkownik podaje login i wybrane przez siebie hasło.
        </ListElement>
      </OrderedList>
    </TextPart>
    <TextPart>
      <Header>§ 3. Użytkowanie Serwisu, odpowiedzialność Użytkownika</Header>
      <OrderedList>
        <ListElement>
          W celu przypomnienia hasła Użytkownik może skorzystać z przypomnienia hasła. Wówczas na adres e-mail zapisany w systemie zostanie wysłany link, umożliwiający Użytkownikowi samodzielne aktualizowanie hasła.
        </ListElement>
        <ListElement>
          Użytkownik zobowiązany jest do zapewnienia bezpieczeństwa oraz nieprzekazywania osobom trzecim loginu i hasła dostępu do Serwisu.
        </ListElement>
        <ListElement>
          Zabronione jest korzystanie z Serwisu w sposób sprzeczny z prawem, dobrymi obyczajami i&nbsp;zasadami współżycia społecznego.
        </ListElement>
        <ListElement>
          W przypadku naruszenia przez Użytkownika zasad dostęp Użytkownika do konta może zostać zablokowany.
        </ListElement>
        <ListElement>
          Użytkownik ponosi pełną odpowiedzialność za treści publikowane w swoich komentarzach. Przesyłanie komentarze nie mogą zawierać treści obraźliwych, wulgarnych lub naruszających dobro osób trzecich.
        </ListElement>
        <ListElement>
          W związku z organizowanymi szkoleniami Użytkownicy Serwisu mogą brać udział w promocjach i&nbsp;konkursach ogłaszanych w Serwisie, jeżeli takie zostaną zorganizowane przez Właściciela Serwisu. Udział w nich podlega odrębnym warunkom uczestnictwa sformułowanych odpowiednio dla każdej promocji lub konkursu.
        </ListElement>
      </OrderedList>
    </TextPart>
    <TextPart>
      <Header>§ 4. Postanowienia końcowe</Header>
      <OrderedList>
        <ListElement>
          Serwis oraz jego zawartość, w tym prezentowane znaki towarowe i opublikowane materiały stanowią własność Właściciela Serwisu. Jeżeli nie wskazano inaczej, Użytkownicy strony mają prawo przeglądać zawartość strony, kopiować, rozpowszechniać i drukować lub zapisywać pliki z&nbsp;materiałami zamieszczonymi na tych stronach wyłącznie w zakresie użytku służbowego chyba że poszczególne materiały zostały wydane do użytku zewnętrznego (np. odpowiednie zaświadczenia/certyfikaty).
        </ListElement>
        <ListElement>
          Operator serwisu działając na zlecenie Właściciela Serwisu zastrzega sobie prawo do:<br />
          1) zmiany funkcjonalności Serwisu,<br />
          2) czasowego zawieszania działalności Serwisu,<br />
          3) niezwłocznego usunięcia opublikowanych treści w przypadku złamania przez Użytkownika Zasad korzystania z Platformy Szkoleniowej lub prawa powszechnie obowiązującego.
        </ListElement>
        <ListElement>
          Operator serwisu, Właściciel Serwisu nie ponoszą  odpowiedzialności w szczególności za:<br />
          1) jakiekolwiek szkody wynikające z nieprawidłowego działania Serwisu lub jego czasowej niedostępności wynikających z przyczyn leżących po stronie Użytkownika Serwisu,<br />
          2) jakiekolwiek szkody wynikające z przejęcia hasła użytkownika/podania przez Użytkownika hasła osobie trzeciej; przez osoby trzecie,<br />
          3) naruszenia przez Użytkownika w jakikolwiek sposób majątkowych praw autorskich lub dóbr osobistych osób trzecich.
        </ListElement>
        <ListElement>
          Wszelkie nieprawidłowości w działaniu Serwisu oraz uwagi można wysyłać na adres e-mail: kontakt@vwbank-szkolenia.pl.
        </ListElement>
        <ListElement>
          Informacje dotyczące wykorzystywania plików Cookies oraz ochrony danych osobowych w&nbsp;związku z korzystaniem przez Użytkownika z Serwisu zostały opisane w Polityce Prywatności i wykorzystywania plików cookies, a także w klauzuli obowiązku informacyjnego dostępnej w Serwisie.
        </ListElement>
        <ListElement>
          Warunki, zasady odbywania szkoleń innych niż udostępnianych za pośrednictwem Platformy Szkoleniowej regulują właściwe dokumenty wewnętrzne grupy Volkswagen Financial Services, w&nbsp;tym Regulamin Szkoleń.
        </ListElement>
        <ListElement>
          Właściciel Serwisu zastrzega prawo do zmiany warunków Zasad (Regulaminu, Polityki prywatności oraz wykorzystywania plików cookies). Poinformowanie Użytkownika o zmianie treści tego nastąpi za pośrednictwem wskazanego przez Użytkownika w Serwisie adresu poczty elektronicznej. Informacja ta będzie zawierała zmienioną treść lub odnośnik do miejsca, gdzie zmieniona treść została umieszczona. Użytkownik akceptuje powyższą formę powiadomienia o zmianach.
        </ListElement>
      </OrderedList>
    </TextPart>
  </TextContainer>
);
