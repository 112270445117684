import React from "react";

import {
  TextContainer,
  Text,
  TextPart,
  Header,
  Table,
  Th,
  Td,
} from "../regulations.styled";

export const TrainingRegulationsContent = () => (
  <TextContainer>
    <TextPart>
      <Header>1. Informacja wstępna</Header>
      <Text>
        1.1. W każdym przypadku, gdy mowa o szkoleniu, zapisy niniejszego regulaminu dotyczą również pozostałych działań (egzamin/badanie potrzeb szkoleniowych/działania przed i po szkoleniu) prowadzonych przez Volkswagen Financial Services dla pracowników Autoryzowanych Dealerów.
      </Text>
      <br />
      <Text>
        1.2. Głównym systemem do zarządzania szkoleniami i zapisami na szkolenia jest Platforma Szkoleniowa VWFS (www.vwbank-szkolenia.pl)
      </Text>
      <br />
      <Text>
        1.3. Szkolenia mogą być realizowane w formie on-line lub w formie stacjonarnej.
      </Text>
      <br />
      <Text>
        1.4. W każdym przypadku, gdy mowa jest o organizatorze, rozumie się przez to VWFS (Volkswagen Financial Services) lub podwykonawcę VWFS, organizującego szkolenie.
      </Text>
    </TextPart>
    <TextPart>
      <Header>2. Szkolenia obowiązkowe i opcjonalne</Header>
      <Text>
        2.1. Wyróżnia się 3 kategorie szkoleń:<br />
        2.1.1. Szkolenia poziomu 1 (SP1) – szkolenia obowiązkowe dla każdego użytkownika* Platformy Szkoleniowej VWFS zatrudnionego na stanowisku „Sprzedawca aut i/lub finansowania dla pojazdów nowych” i/lub „Sprzedawca aut i/lub finansowania dla pojazdów używanych”, który nie będzie posiadać pełnych dostępów do systemów sprzedażowych UFO/NSU.<br />
        2.1.2. Szkolenia poziomu 2 (SP2) – szkolenia obowiązkowe dla każdego użytkownika* Platformy Szkoleniowej VWFS zatrudnionego na stanowisku „Sprzedawca aut i/lub finansowania dla pojazdów nowych” i/lub „Sprzedawca aut i/lub finansowania dla pojazdów używanych” i/lub „Sprzedawca ubezpieczeń”, który będzie posiadać pełne dostępy do systemów sprzedażowych UFO/NSU.<br />
        2.1.3. Szkolenia poziomu 3 (SP3) – szkolenia nieobowiązkowe.
      </Text>
      <br />
      <Text>
        2.2. Szkoleniom przypisano następujące kategorie:<br />
        2.2.1. On-boarding – SP1, SP2<br />
        2.2.2. Standardy Współpracy z VWFS dla sprzedawców samochodów nowych, Standardy Współpracy z VWFS dla sprzedawców samochodów używanych – SP1, SP2<br />
        2.2.3. Standardy Współpracy z VWSU dla sprzedawców ubezpieczeń – SP2 (tylko dla stanowiska „Sprzedawca ubezpieczeń”)<br />
        2.2.4. Egzamin ogólny i produktowy Cardif – SP2<br />
        2.2.5. Egzamin produktowy TUiR Allianz S.A., Egzamin produktowy STU Ergo Hestia S.A., Egzamin produktowy TUiR Warta S.A./HDI, Egzamin produktowy VW Versicherung – SP2 (tylko dla stanowiska „Sprzedawca ubezpieczeń”)<br />
        2.2.6. Szkolenia Toolbox, Szkolenia produktowe, webinary – SP3
      </Text>
      <br/>
      <Text>
        2.3. VWFS zastrzega sobie prawo do zmiany kategorii powyższych szkoleń.
      </Text>
      <br />
      <Text>
        2.4. Nowe szkolenia, nieuwzględnione w powyższym zestawieniu, zostaną w komunikacji do sieci określone odpowiednim oznaczeniem (SP1/SP2/SP3).
      </Text>
      <br />
      <Text>
        2.5. Szkolenia wskazane jako obowiązkowe dotyczą wszystkich osób zatrudnionych na wybranym stanowisku w sieci sprzedaży, wskazanych jako grupa docelowa danego szkolenia.
      </Text>
    </TextPart>
    <TextPart>
      <Header>3. Zapotrzebowanie</Header>
      <Text>
        3.1. Nie ma potrzeby zgłaszania zapotrzebowania na szkolenia obowiązkowe.
      </Text>
      <br />
      <Text>
        3.2. Zapotrzebowanie na szkolenia uzupełniające powinno być zgłaszane do Regionalnego Kierownika Sprzedaży, odpowiedzialnego za dany region lub do Zespołu Szkoleniowego VWFS na adres szkoleniavwfs@vwfs.com
      </Text>
    </TextPart>
    <TextPart>
      <Header>4. Zaproszenia i zapisy na szkolenia</Header>
      <Text>
        4.1. Zaproszenie na szkolenie wysyłane jest nie później niż na 14 dni przed dniem szkolenia. Każde zaproszenie zawiera informację na temat zakresu tematycznego, lokalizacji, warunków noclegu (w przypadku szkoleń 2-dniowych) oraz informacje organizacyjne.
      </Text>
      <br />
      <Text>
        4.2. Pracownicy sieci sprzedaży zapisują się na szkolenia poprzez Platformę Szkoleniową VWFS, chyba że w treści zaproszenia podano inaczej.
      </Text>
      <br />
      <Text>
        4.3. Pracownicy sieci sprzedaży dokonują zapisu na jedno z „wolnych” miejsc na dane szkolenie. O udziale decyduje kolejność zgłoszeń, chyba że w treści zaproszenia podano inaczej. Zapisy są możliwe do wyczerpania dostępnych wolnych miejsc.
      </Text>
      <br />
      <Text>
        4.4. W przypadku  braku wolnych miejsc na szkolenie można zgłosić zapotrzebowanie zgodnie z pkt. 3.2.
      </Text>
      <br />
      <Text>
        4.5. VWFS lub firma działająca na zlecenie VWFS na 7 dni przed dniem szkolenia dokonuje telefonicznego potwierdzenia obecności zapisanych uczestników.
      </Text>
    </TextPart>
    <TextPart>
      <Header>5. Zadanie przed szkoleniem</Header>
      <Text>
        5.1. Organizator może wyznaczyć obowiązkowe zadanie przed szkoleniem wskazując termin jego wykonania.
      </Text>
      <br />
      <Text>
        5.2. Informacja o obowiązkowym zadaniu jest przekazywana w komunikacji dotyczącej szkolenia.
      </Text>
      <br />
      <Text>
        5.3. Brak zaliczenia zadania w wyznaczonym terminie może skutkować usunięciem uczestnika z listy szkolenia.
      </Text>
    </TextPart>
    <TextPart>
      <Header>6. Koszty szkoleń, noclegów, wyżywienia, parkingu</Header>
      <Text>
        6.1. Koszty szkoleń pokrywa VWFS, z zastrzeżeniem pkt. 7.
      </Text>
      <br />
      <Text>
        6.2. W przypadku szkoleń dwudniowych VWFS zapewnia nocleg dla uczestnika i pokrywa jego koszt. W treści zaproszenia VWFS wskazuje rodzaj rezerwowanych pokoi (1-/2-osobowe).
      </Text>
      <br />
      <Text>
        6.3. Jeśli uczestnik potrzebuje skorzystać z noclegu dzień przed szkoleniem lub ma indywidualne zapotrzebowanie, którego rezerwacja dokonana przez VWFS nie pokrywa, może dokonać indywidualnej rezerwacji bezpośrednio w hotelu na własny koszt.
      </Text>
      <br />
      <Text>
        6.4. Jeżeli uczestnik nie zamierza korzystać z noclegu zapewnianego przez VWFS, powinien o tym poinformować organizatora nie później niż na 7 dni przed dniem szkolenia. Brak informacji będzie skutkować obciążeniem uczestnika kosztem noclegu poniesionym przez VWFS.
      </Text>
      <br />
      <Text>
        6.5. VWFS pokrywa koszty wyżywienia i napoi dostępnych podczas szkolenia oraz kolacji w przypadku szkoleń dwudniowych.
      </Text>
      <br />
      <Text>
        6.6. Koszty parkingu pokrywane są przez uczestnika szkolenia.
      </Text>
    </TextPart>
    <TextPart>
      <Header>7. Obciążenie uczestnika kosztami szkolenia</Header>
      <Text>
        7.1. VWFS obciąży uczestnika zryczałtowanymi kosztami szkolenia w wysokości wskazanej w pkt. 7.2. w przypadku:<br />
        7.1.1. rezygnacji na mniej niż 7 dni przed dniem szkolenia stacjonarnego lub on-line;<br />
        7.1.2. niestawienia się na szkoleniu, w tym również niestawienia się drugiego dnia szkolenia w przypadku szkoleń dwudniowych (dotyczy szkoleń stacjonarnych oraz on-line).
      </Text>
      <br />
      <Text>
        7.2. Wysokość zryczałtowanych kosztów szkolenia:
      </Text>
      <Table>
        <thead>
          <tr>
            <Th></Th>
            <Th>Rodzaj szkolenia</Th>
            <Th>Koszt</Th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <Td rowSpan={2}>rezygnacja na mniej niż 7 dni przed dniem szkolenia</Td>
            <Td>szkolenie&nbsp;stacjonarne</Td>
            <Td>300</Td>
          </tr>
          <tr>
            <Td>szkolenie&nbsp;on-line</Td>
            <Td>200</Td>
          </tr>
          <tr>
            <Td rowSpan={2}>niestawienia się na szkoleniu, w tym również niestawienie się drugiego dnia szkolenia w przypadku szkoleń dwudniowych</Td>
            <Td>szkolenie&nbsp;stacjonarne</Td>
            <Td>500</Td>
          </tr>
          <tr>
            <Td>szkolenie&nbsp;on-line</Td>
            <Td>400</Td>
          </tr>
        </tbody>
      </Table>
      <br />
      <Text>
        7.3. VWFS nie obciąży uczestnika zryczałtowanymi kosztami szkolenia w przypadku rezygnacji ze szkolenia na mniej niż 7 dni przed dniem szkolenia, jeżeli uczestnik zgłosi swoją nieobecność i przedstawi zwolnienie lekarskie najpóźniej pierwszego dnia szkolenia. Po tym terminie zwolnienia nie będą honorowane.
      </Text>
      <br />
      <Text>
        7.4. W indywidualnych przypadkach VWFS może odstąpić od obciążenia uczestnika zryczałtowanymi kosztami szkolenia.
      </Text>
    </TextPart>
    <TextPart>
      <Header>8. Zadanie po szkoleniu</Header>
      <Text>
        8.1. Organizator może wyznaczyć obowiązkowe zadanie po szkoleniu.
      </Text>
      <br />
      <Text>
        8.2. Zadanie może być potraktowane jako integralna część szkolenia. Oznacza to, że brak zaliczenia zadania jest traktowany równoznacznie z brakiem zaliczenia szkolenia.
      </Text>
      <br />
      <Text>
        8.3. Informacja o obowiązkowym zadaniu jest przekazywana w komunikacji dotyczącej szkolenia lub podczas szkolenia.
      </Text>
    </TextPart>
    <TextPart>
      <Header>9. Postanowienia końcowe.</Header>
      <Text>
        9.1. Regulamin wchodzi w życie w dniu 31.07.2023r.
      </Text>
      <br />
      <Text>
        9.2. Obowiązujący regulamin jest dostępny na Platformie Szkoleniowej VWFS.
      </Text>
      <br />
      <Text>
        9.3. VWFS zastrzega sobie prawo do jednostronnej zmiany postanowień Regulaminu. Wprowadzone zmiany mają zastosowanie do szkoleń, na które zapis następuje po zmianie Regulaminu.
      </Text>
    </TextPart>
  </TextContainer>
);
