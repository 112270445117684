import styled from "styled-components";

import { ReactComponent as TimeSvg } from "../../../../images/clock.svg";
import { Color, FontWeight } from "../../../../theme";
import { listDesktopRowStyles } from "../filesToDownload/filesToDownload.styled";

export const Container = styled.div`
  ${listDesktopRowStyles}
  padding: 20px 0;
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  font-size: 15px;
  color: ${Color.gray9};
  flex-shrink: 0;

  &:nth-of-type(1) {
    flex: 3;
    gap: 30px;
  }

  &:nth-of-type(2) {
    gap: 10px;
    margin-left: auto;
  }
`;

export const Thumbnail = styled.img`
  display: block;
  width: 110px;
`;

export const Title = styled.p`
  font-size: 15px;
  line-height: normal;
  color: ${Color.deepGray};
`;

export const TimeIcon = styled(TimeSvg)`
  width: 28px;
  height: 28px;
`;

export const Time = styled.p`
  font-size: 15px;
  font-weight: ${FontWeight.SemiBold};
  line-height: normal;
  color: ${Color.deepGray};
`;
