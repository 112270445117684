import React from "react";

import {
  TextContainer,
  TextPart,
  Text,
  Header,
  OrderedList,
  ListElement,
  UnorderedList,
} from "../regulations.styled";

export const InfoClauseContent = () => (
  <TextContainer>
    <TextPart>
      <Header>Administratorzy danych:</Header>
      <Text>
        Współadministratorami Twoich danych osobowych są: Volkswagen Bank GmbH Sp. z o.o. Oddział w Polsce (dalej: „VW Bank”), Volkswagen Financial Services Polska Sp. z o.o. (dalej: „VW FSP”) oraz Volkswagen Serwis Ubezpieczeniowy Sp. z o.o. (dalej: „VW SU”) - wszystkie z siedzibą w Warszawie, Rondo ONZ 1, 00-124 Warszawa.
        <br />
        Możesz się z nami kontaktować przez e-mail: szkoleniavwfs@vwfs.com lub pocztą na adresy naszych siedzib.
      </Text>
    </TextPart>
    <TextPart>
      <Header>Inspektorzy ochrony danych:</Header>
      <Text>
        Każdy z nas wyznaczył inspektora ochrony danych, z którym możesz się skontaktować przez e-mail (VW Bank: IOD_VWB@vwfs.com, VW FSP: IOD_VWL@vwfs.com, VW SU: IOD_VWSU@vwfs.com) lub pocztą na adres siedziby danego administratora. Z inspektorami ochrony danych możesz się kontaktować we wszystkich sprawach dotyczących przetwarzania danych osobowych oraz korzystania z praw związanych z przetwarzaniem danych.
      </Text>
    </TextPart>
    <TextPart>
      <Header>Cele oraz podstawy prawne przetwarzania danych osobowych:</Header>
      <Text>
        Będziemy lub możemy przetwarzać Twoje dane osobowe, aby:
      </Text>
      <OrderedList>
        <ListElement>
          świadczyć usługę korzystania z Platformy Szkoleniowej, polegającą m.in. na założeniu i utrzymaniu konta w Serwisie,
          <UnorderedList>
            <ListElement>
              podstawa prawna: odpowiednio zawarcie i wykonanie umowy (umowy o świadczenie usług drogą elektroniczną) oraz wypełnienie obowiązków wynikających z przepisów prawa dotyczących świadczenia usług drogą elektroniczną;
            </ListElement>
            <ListElement>
              okres przetwarzania: okres korzystania przez Ciebie z Platformy Szkoleniowej (okres zatrudnienia u któregokolwiek z nas lub okres współpracy z nami);
            </ListElement>
          </UnorderedList>
        </ListElement>
        <ListElement>
          realizować cele szkoleniowe, w tym organizować i przeprowadzać szkolenia, sporządzać analizy i statystyki szkoleniowe, prowadzić ewaluację wiedzy, przeprowadzać identyfikacje potrzeb szkoleniowo-rozwojowych w celu poprawy skuteczności przeprowadzanych szkoleń, wydawać zaświadczenia/certyfikaty szkoleniowe, zarządzać egzaminami/testami:
          <UnorderedList>
            <ListElement>
              podstawa prawna: przepisy prawa (w przypadku szkoleń obligatoryjnych lub innych regulowanych przepisami prawa oraz w zakresie w jakim obowiązek wydawania zaświadczenia/certyfikatów szkoleniowych wynika z przepisów prawa); w pozostałych przypadkach - nasz prawnie uzasadniony interes (polegający odpowiednio na podnoszeniu kwalifikacji, podnoszeniu jakości szkoleń, zwiększaniu skuteczności prowadzonych szkoleń; identyfikowaniu potrzeb szkoleniowych, zarządzaniu szkoleniami);
            </ListElement>
            <ListElement>
              okres przetwarzania: odpowiednio okres korzystania przez Ciebie z Platformy Szkoleniowej (do czasu zakończenia przez VW FSP, VW Bank, VW SU lub przez Ciebie współpracy z Dealerem - w zależności od tego, które ze zdarzeń nastąpi jako pierwsze) lub okres wymagany przepisami prawa (tam, gdzie podstawą jest realizacja obowiązku prawnego);
            </ListElement>
          </UnorderedList>
        </ListElement>
        <ListElement>
          integrować społeczność Użytkowników Platformy Szkoleniowej w celu wymiany doświadczeń, opinii itp.:
          <UnorderedList>
            <ListElement>
              podstawa prawna: prawnie uzasadniony interes administratora (tj. budowa zaangażowania Użytkowników Platformy Szkoleniowej w wymianę doświadczeń, dzielenie się wiedzą, przekazywanie informacji zwrotnej w obszarach współpracy);
            </ListElement>
            <ListElement>
              okres przetwarzania: okres korzystania przez Ciebie z Platformy Szkoleniowej (okres zatrudnienia u któregokolwiek z nas lub okres współpracy z nami);
            </ListElement>
          </UnorderedList>
        </ListElement>
        <ListElement>
          ułatwiać kontakty między Pracownikami i Współpracownikami każdego z administratorów oraz Pracownikami i Współpracownikami innych spółek z Grupy Volkswagen poprzez umieszczenie wizerunku (zdjęcia) w Platformie Szkoleniowej:
          <UnorderedList>
            <ListElement>
              podstawa prawna: Twoja zgoda wyrażona poprzez działanie (dobrowolne opublikowanie przez Ciebie w Platformie Szkoleniowej Twojego wizerunku);
            </ListElement>
            <ListElement>
              okres przetwarzania danych: do momentu wycofania zgody, nie dłużej niż do momentu zakończenia zatrudnienia u nas lub współpracy z nami;
            </ListElement>
          </UnorderedList>
        </ListElement>
        <ListElement>
          rozpatrywać reklamacje i wnioski, w tym żądania z zakresu ochrony danych osobowych:
          <UnorderedList>
            <ListElement>
              podstawa prawna: w zakresie, w jakim rozpatrywanie reklamacji i wniosków podlega pod przepisy prawa - wypełnienie obowiązku prawnego ciążącego na administratorze; w pozostałym zakresie - prawnie uzasadniony interes administratora (tj. możliwość rozpatrywania reklamacji, wniosków i żądań oraz udzielenia odpowiedzi na nie);
            </ListElement>
            <ListElement>
              okres przetwarzania: do momentu rozpatrzenia reklamacji lub wniosku oraz udzielenia odpowiedzi;
            </ListElement>
          </UnorderedList>
        </ListElement>
        <ListElement>
          bronić się przed roszczeniami lub dochodzić roszczeń w związku ze świadczeniem usługi korzystania z Platformy Szkoleniowej oraz innymi działaniami realizowanymi za pośrednictwem Platformy Szkoleniowej:
          <UnorderedList>
            <ListElement>
              podstawa prawna: prawnie uzasadniony interes administratora (tj. możliwość obrony przed roszczeniami lub dochodzenia roszczeń przez VW Bank, VW FSP oraz VW SU);
            </ListElement>
            <ListElement>
              okres przetwarzania: do momentu przedawnienia roszczeń;
            </ListElement>
          </UnorderedList>
        </ListElement>
        <ListElement>
          realizować nasze cele wewnętrzne (tj. archiwizować dane, zapewnić funkcjonowania systemu kontroli wewnętrznej, prowadzić audyty, zarządzać ryzykiem operacyjnym, zapewniać bezpieczeństwa systemów informatycznych oraz informacji):
          <UnorderedList>
            <ListElement>
              podstawa prawna: prawnie uzasadniony interes administratora (tj. możliwość zabezpieczenia informacji na wypadek prawnej potrzeby wykazania przez nas faktów związanych ze świadczeniem usług lub zgodności działania z przepisami prawa, możliwość stabilnego i bezpiecznego prowadzenia przez nas działalności, w tym zapewnienia bezpieczeństwa danych osobowych);
            </ListElement>
            <ListElement>
              okres przetwarzania: nie dłużej niż do najdłuższego z ww. okresów.
            </ListElement>
          </UnorderedList>
        </ListElement>
      </OrderedList>
    </TextPart>
    <TextPart>
      <Header>Odbiorcy danych osobowych:</Header>
      <Text>
        Możemy przekazywać Twoje dane osobowe dostawcom systemów, podmiotom przetwarzającym dane osobowe na nasze zlecenie, m.in. dostawcy Platformy Szkoleniowej, kurierom, podmiotom świadczącym usługi archiwizacji oraz niszczenia dokumentów oraz inne usługi administracyjne - przy czym takie podmioty przetwarzają dane na podstawie umowy z danym administratorem.
        <br />
        Twoje dane osobowe mogą być także udostępniane podmiotom upoważnionym do ich otrzymania na podstawie przepisów prawa, w tym organom nadzorczym.
        <br />
        Twoje dane mogą być przekazywane także podmiotom świadczącym usługi związane z organizacją i przeprowadzaniem szkoleń, analizą potrzeb szkoleniowo-rozwojowych.
      </Text>
    </TextPart>
    <TextPart>
      <Header>Przekazywanie danych osobowych poza Europejski Obszar Gospodarczy:</Header>
      <Text>
        Możemy przekazywać Twoje dane osobowe podmiotom, które mogą lub będą przetwarzać te dane poza Europejskim Obszarem Gospodarczym. Przekazując te dane zapewniamy odpowiedni stopień ochrony (zabezpieczeń). Działamy na podstawie np. decyzji Komisji Europejskiej stwierdzającej odpowiedni stopień ochrony danych osobowych lub standardowych klauzul ochrony danych przyjętych przez Komisję Europejską. Szczegółowe informacje o transferze danych osobowych poza Europejski Obszar Gospodarczy, w tym kopie zabezpieczeń, można uzyskać kontaktując się z nami przez e-mail: szkoleniavwfs@vwfs.com lub pocztą na adresy naszych siedzib.
      </Text>
    </TextPart>
    <TextPart>
      <Header>Twoje prawa:</Header>
      <Text>
        Masz prawo:
      </Text>
      <OrderedList>
        <ListElement>
          mieć dostęp do Twoich danych osobowych oraz żądać ich sprostowania, usunięcia lub ograniczenia ich przetwarzania;
        </ListElement>
        <ListElement>
          wnieść sprzeciw wobec przetwarzania Twoich danych osobowych - w zakresie, w jakim podstawą prawną przetwarzania jest przesłanka prawnie uzasadnionego interesu administratora;
        </ListElement>
        <ListElement>
          wycofać w dowolnym momencie zgodę na przetwarzanie Twoich danych osobowych w zakresie, w jakim podstawą prawną przetwarzania jest zgoda. Wycofanie zgody nie ma wpływu na zgodność z prawem przetwarzania, które wykonaliśmy na podstawie zgody przed jej wycofaniem. Zgodę możesz wycofać kontaktując się z nami przez e-mail: szkoleniavwfs@vwfs.com lub pocztą na adresy naszych siedzib lub usuwając swój wizerunek lub wizytówkę z Platformy Szkoleniowej;
        </ListElement>
        <ListElement>
          przenieść dane osobowe, tj. otrzymać od nas Twoje dane osobowe, w ustrukturyzowanym, powszechnie używanym formacie nadającym się do odczytu maszynowego - w zakresie, w jakim podstawą prawną przetwarzania jest zgoda lub wykonanie umowy. Możesz przesłać te dane innemu administratorowi danych;
        </ListElement>
        <ListElement>
          otrzymać od nas jako współadministratorów zasadniczą treść uzgodnień w zakresie ochrony danych osobowych;
        </ListElement>
        <ListElement>
          wnieść skargę do organu nadzorczego zajmującego się ochroną danych osobowych.
        </ListElement>
      </OrderedList>
    </TextPart>
    <TextPart>
      <Header>Informacja o wymogu podania danych osobowych:</Header>
      <Text>
        Dane osobowe, o które pytamy przy rejestracji, są konieczne, aby założyć Ci konto w Platformie Szkoleniowej. Bez podania danych osobowych nie jest możliwe korzystanie przez Ciebie z Platformy Szkoleniowej.
        <br />
        Podanie danych w postaci wizerunku i danych zawartych w wizytówce jest dobrowolne.
      </Text>
    </TextPart>
  </TextContainer>
);
