import React, { useState, useEffect, useCallback, useRef } from "react";
import i18n from "i18next";
import MediaQuery from "react-responsive";
import Cookies from "universal-cookie";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";

import { FullLogo } from "../../UIElements/fullLogo/fullLogo.component";
import { TutorialStepPopup } from "../../components/tutorialStepPopup/tutorialStepPopup.component";
import { TargetPosition } from "../../components/tutorialStepPopup/tutorialStepPopup.constants";
import { TutorialStep } from "../../components/tutorialPopup/tutorialPopup.constants";
import { BrandReset } from "../../appSettings/brandReset/brandReset.component";
import { selectTutorialCurrentStep } from "../../redux/common/common.selectors";
import { ReactComponent as LockIcon } from "../../images/lock.svg";
import { ReactComponent as ManagementIcon } from "../../images/management.svg";
import { ReactComponent as NotificationsIcon } from "../../images/notifications.svg";
import { ReactComponent as LogoutIcon } from "../../images/logout.svg";
import { ReactComponent as ProfileIcon } from "../../images/profile.svg";
import { renderWhenTrue } from "../../helpers/rendering";
import { Icon as OldIcon } from "../../UIElements";
import { AppConfig, Theme } from "../../appConfig";
import { colors, breakpoints } from "../../UIGlobals";
import { Logout } from "../../userContext";
import { phpApi } from "../../api";

import { DisabledItemTheme } from "./components/navItem.constants";
import NavItem from "./components/navItem.component";
import {
  DefaultNavItems,
  NavRouteName,
  NavRoute,
  CompetenceRouteName,
} from "./mainNavigation.constants";
import {
  Sidebar,
  Background,
  Nav,
  RightNavSelect,
  ArrowDown,
  LogoLink,
  TutorialWrapper,
} from "./mainNavigation.styled";

const MainNavigation = (props) => {
  const {
    mobileVisible,
    menuToggle,
    settings,
    availableRoutes = {},
    isOnboardingUser,
  } = props;
  const location = useLocation();
  const sidebarRef = useRef(null);
  const tutorialCurrentStep = useSelector(selectTutorialCurrentStep);
  const LogoLinkRoute = availableRoutes[NavRouteName.Dashboard] ? NavRoute.Default : NavRoute.Onboarding;
  const [arrowButtonVisible, setArrowButtonVisible] = useState(true);
  const [activeUrl, setActiveUrl] = useState(location.pathname);

  useEffect(() => {
    setActiveUrl(`/${location.pathname.split("/")[1]}`);
  }, [location.pathname]);

  useEffect(() => {
    const sidebar = sidebarRef.current;
    if (sidebar) {
      if (sidebar.scrollHeight > sidebar.offsetHeight + 35) {
        setArrowButtonVisible(true);
      } else {
        setArrowButtonVisible(false);
      }
    }
  }, [sidebarRef]);

  const isUrlActive = useCallback(
    (urls) => urls.find((url) => activeUrl === url),
    [activeUrl],
  );

  const handleRedirect = () => {
    phpApi.post("/api/user/management", {}).then(({ data }) => {
      const cookies = new Cookies();

      Object.keys(data.cookies).forEach((element) => {
        cookies.set(element, data.cookies[element], { path: "/"});
      });

      window.open(`${AppConfig.api}${data.redirect}`);
    });
  };

  const handleScrollEvent = (event) => {
    const element = event.target;
    if (element.scrollHeight > element.offsetHeight + 35 && element.scrollTop < 1) {
      setArrowButtonVisible(true);
    }
    if (element.scrollTop > 10) {
      setArrowButtonVisible(false);
    }
  }

  const handleScrollDown = (event) => {
    const element = event.target;
    const parent = element.parentNode.parentElement;
    parent.scrollBy(0, 700);
    setArrowButtonVisible(false);
    event.stopPropagation();
  };

  const renderNavItems = useCallback(() => (
    DefaultNavItems.map((item) => {
      if (availableRoutes[item.routeName]) {
        if (isOnboardingUser && item.routeName === CompetenceRouteName) {
          return null;
        }

        return (
          <TutorialWrapper key={item.label}>
            <NavItem
              href={item.href}
              Icon={item.Icon}
              active={isUrlActive(item.urls)}
            >
              {item.label}
            </NavItem>
            {item.routeName === NavRouteName.KnowledgeBase && tutorialCurrentStep === TutorialStep.KnowledgeBase && (
              <TutorialStepPopup
                topPositionInPx={-50}
                leftPositionInPx={90}
                targetTopInPx={48}
                targetLeftInPx={-93}
                arrowTopInPx={52}
                smallTarget
                targetPosition={TargetPosition.Left}
              />
            )}
            {item.routeName === NavRouteName.Trainings && tutorialCurrentStep === TutorialStep.Trainings && (
              <TutorialStepPopup
                topPositionInPx={-100}
                leftPositionInPx={90}
                targetTopInPx={98}
                targetLeftInPx={-93}
                arrowTopInPx={105}
                smallTarget
                targetPosition={TargetPosition.Left}
              />
            )}
          </TutorialWrapper>
        );
      }

      if (Theme.DisabledMenuItems === DisabledItemTheme.Locked && item.lockVisible) {
        return (
          <NavItem key={item.label}>{item.label}</NavItem>
        );
      }

      return null;
    })
  ), [availableRoutes, isUrlActive, tutorialCurrentStep, isOnboardingUser]);

  const renderLockedOnboardingItems = renderWhenTrue(() => (
    <>
      <NavItem>
        {i18n.t("app.mainNavigation.knowledgeBase")}
      </NavItem>
      <NavItem>
        {i18n.t("app.mainNavigation.formsAndTests")}
      </NavItem>
    </>
  ));

  const renderChangeBrandsItem = renderWhenTrue(() => (
    <NavItem href="#" Icon={LockIcon}>
      <RightNavSelect>
        <BrandReset>
          {i18n.t("app.mainNavigation.changeBrand")}
        </BrandReset>
      </RightNavSelect>
    </NavItem>
  ));

  const renderArrowButton = renderWhenTrue(() => (
    <ArrowDown onClick={handleScrollDown}>
      <OldIcon icon="arrow_left" size="17" color={colors().black} rotation="-90deg" />
    </ArrowDown>
  ));

  return (
    <>
      <Sidebar
        ref={sidebarRef}
        $mobileVisible={mobileVisible}
        onScroll={handleScrollEvent}
        onClick={menuToggle}
        disabled={!!tutorialCurrentStep}
      >
        <LogoLink to={LogoLinkRoute}>
          <FullLogo cursorPointer />
        </LogoLink>
        <Nav>
          {renderNavItems()}
          {renderLockedOnboardingItems(isOnboardingUser)}
          {availableRoutes[NavRouteName.Management] && (
            <MediaQuery query={`(${breakpoints().minL})`}>
              <NavItem href="#" onClick={handleRedirect} Icon={ManagementIcon}>
                {i18n.t("app.mainNavigation.management")}
              </NavItem>
            </MediaQuery>
          )}
          <MediaQuery query={`(${breakpoints().maxL})`}>
            {renderChangeBrandsItem(!!settings.brands.length)}
            <NavItem href={NavRoute.Notifications} Icon={NotificationsIcon}>
              {i18n.t("app.mainNavigation.notifications")}
            </NavItem>
            <TutorialWrapper>
              <NavItem href={NavRoute.Profile} Icon={ProfileIcon}>
                {i18n.t("app.mainNavigation.profile")}
              </NavItem>
              {tutorialCurrentStep === TutorialStep.Profile && (
                <TutorialStepPopup
                  topPositionInPx={-200}
                  leftPositionInPx={90}
                  targetTopInPx={198}
                  targetLeftInPx={-93}
                  arrowTopInPx={202}
                  smallTarget
                  targetPosition={TargetPosition.Left}
                />
              )}
            </TutorialWrapper>
            <Logout padding="0">
              <NavItem href="#" Icon={LogoutIcon}>
                {i18n.t("app.mainNavigation.logout")}
              </NavItem>
            </Logout>
            {renderArrowButton(arrowButtonVisible)}
          </MediaQuery>
        </Nav>
      </Sidebar>
      <Background $mobileVisible={mobileVisible} onClick={menuToggle} />
    </>
  );
};

export default MainNavigation;
