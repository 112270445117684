import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { selectUserData } from "../../../../userContext/redux/userContext.selectors";
import { ButtonVariant } from "../../../../components/button/button.constants";
import { simpleDateFromTimestamp } from "../../../../helpers/date";
import { AppConfig } from "../../../../appConfig";
import {
  ListMobileProp,
  ListMobilePropTitle,
  ListMobilePropText,
  ListMobileDownloadButton,
  ListMobileDownloadIcon,
} from "../filesToDownload/filesToDownload.styled";

import {
  Container,
  Header,
  Icon,
  Title,
} from "./certificateItemMobile.styled";

export const CertificateItemMobile = ({ className, id, passed, validityTimestamp }) => {
  const { t } = useTranslation();
  const user = useSelector(selectUserData);
  const date = simpleDateFromTimestamp(validityTimestamp);
  const year = date.slice(6);

  return (
    <Container className={className}>
      <Header>
        <Icon />
        <Title>{t("idd.certificateItemMobile.year", { year })}</Title>
      </Header>
      <ListMobileProp>
        <ListMobilePropTitle>{t("idd.certificateItemMobile.status")}</ListMobilePropTitle>
        <ListMobilePropText $withError={!passed}>
          {passed ? t("idd.certificateItemMobile.passed") : t("idd.certificateItemMobile.notPassed")}
        </ListMobilePropText>
      </ListMobileProp>
      <ListMobileProp>
        <ListMobilePropTitle>{t("idd.certificateItemMobile.endDate")}</ListMobilePropTitle>
        <ListMobilePropText>{date}</ListMobilePropText>
      </ListMobileProp>
      <ListMobileDownloadButton
        variant={ButtonVariant.Outlined}
        disabled={!passed}
        onClick={() => window.open(`${AppConfig.api}/download/certificate/${id}?X-AUTH-TOKEN=${user.token}`)}
      >
        <ListMobileDownloadIcon $isDisabled={!passed} />
        {t("idd.certificateItemMobile.download")}
      </ListMobileDownloadButton>
    </Container>
  );
};
