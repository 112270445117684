import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import { ThumbnailList } from "../../../../components/thumbnailList/thumbnailList.component";
import { Loader } from "../../../../components/loader/loader.component";
import { selectContents } from "../../redux/idd.selectors";

import { Container, Wrapper, StyledSeparator } from "./lessonList.styled";

export const LessonList = () => {
  const contents = useSelector(selectContents);

  return (
    <Container>
      <Wrapper>
        {contents?.length ? (
          contents.map(({ title, items }, index) => (
            <Fragment key={title}>
              <ThumbnailList title={title} thumbnails={items} />
              {index + 1 < contents.length && <StyledSeparator />}
            </Fragment>
          ))
        ) : <Loader />}
      </Wrapper>
    </Container>
  );
};
