import React, { Component } from "react";
import MediaQuery from "react-responsive";

import { breakpoints, colors } from "../../../../UIGlobals";
import { optionClickHandler } from "../quizHelpers";

import { Icon } from "../../../../UIElements/icons";
import { Container, Text, ThumbsContainer, ThumbButton, CorrectAnswerComment, WrongAnswerComment } from "./thumbsQuiz.styled";

export class ThumbsQuiz extends Component {
  state = {
    wrongAnswerComment: "",
    correctAnswerComment: ""
  };

  onOptionClick = (isCorrect, index) => {
    const { data, setDisabledButton } = this.props;
    const { correctText, incorrectText } = data;
    this.setState(optionClickHandler(isCorrect, correctText, incorrectText, setDisabledButton, index));
  }

  handleThumpUpClick = () => this.onOptionClick(this.props.data.correctAnswer);
  handleThumpDownClick = () => this.onOptionClick(!this.props.data.correctAnswer);

  render() {
    const { correctAnswerComment, wrongAnswerComment } = this.state;
    const { text, correctAnswer } = this.props.data;
    const correctAnswerClicked = !!correctAnswerComment.length;
    const wrongAnswerClicked = !!wrongAnswerComment.length;

    const wrongThumbUpClicked = !correctAnswer && wrongAnswerClicked;
    const correctThumbUpClicked = correctAnswer && correctAnswerClicked;
    const wrongThumbDownClicked = correctAnswer && wrongAnswerClicked;
    const correctThumbDownClicked = !correctAnswer && correctAnswerClicked;

    const thumbUpIconColor = wrongThumbUpClicked || correctThumbUpClicked ? colors().white : colors().blue2;
    const thumbDownIconColor = wrongThumbDownClicked || correctThumbDownClicked ? colors().white : colors().blue2;

    return (
      <Container>
        <MediaQuery query={`(${breakpoints().maxL})`}>
          <Text>{text}</Text>
        </MediaQuery>
        <ThumbsContainer>
          <ThumbButton
            correctTheme={correctThumbUpClicked}
            wrongTheme={wrongThumbUpClicked}
            onClick={this.handleThumpUpClick}
          >
            <Icon cursor="pointer" icon="thumb_up" size={40} color={thumbUpIconColor} />
          </ThumbButton>
          <ThumbButton
            correctTheme={correctThumbDownClicked}
            wrongTheme={wrongThumbDownClicked}
            onClick={this.handleThumpDownClick}
          >
            <Icon cursor="pointer" icon="thumb_down" size={40} color={thumbDownIconColor} />
          </ThumbButton>
        </ThumbsContainer>
        <MediaQuery query={`(${breakpoints().minL})`}>
          <div>
            <Text>{text}</Text>
            {correctAnswerClicked && <CorrectAnswerComment>{correctAnswerComment}</CorrectAnswerComment>}
            {wrongAnswerClicked && <WrongAnswerComment>{wrongAnswerComment}</WrongAnswerComment>}
          </div>
        </MediaQuery>
        <MediaQuery query={`(${breakpoints().maxL})`}>
          {correctAnswerClicked && <CorrectAnswerComment>{correctAnswerComment}</CorrectAnswerComment>}
          {wrongAnswerClicked && <WrongAnswerComment>{wrongAnswerComment}</WrongAnswerComment>}
        </MediaQuery>
      </Container>
    );
  }
}
