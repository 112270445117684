import styled, { css } from "styled-components";
import { prop } from "ramda";

import { styleWhenTrue } from "../../../../helpers/rendering";
import { Color, FontWeight } from "../../../../theme";
import { ReactComponent as CompetenceSvg } from "../../images/competence.svg";
import { listDesktopRowStyles } from "../filesToDownload/filesToDownload.styled";

export const Container = styled.div`
  ${listDesktopRowStyles}
  padding: 32px 0;
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  flex: 1;
  font-size: 15px;
  color: ${Color.gray9};
  flex-shrink: 0;

  &:nth-of-type(1) {
    flex: 4;
  }

  &:nth-of-type(2) {
    margin-left: auto;
  }

  ${styleWhenTrue(prop("$withError"), css`
    color: ${Color.error};
  `)}
`;

export const Icon = styled(CompetenceSvg)`
  width: 46px;
  height: auto;
  flex-shrink: 0;
`;

export const Title = styled.p`
  color: ${Color.deepGray};
  font-size: 15px;
  font-weight: ${FontWeight.Bold};
  line-height: 1.3;
`;
