export const SlideType = {
  Introduction: "introduction",
  IntroductionWithFile: "introductionWithFile",
  ThumbsQuiz: "thumbsQuiz",
  MatchWithGroupsQuiz: "matchWithGroupsQuiz",
  TwoTitledAnswersQuiz: "twoTitledAnswersQuiz",
  FourAnswersQuiz: "fourAnswersQuiz",
  TrueOrFalseQuiz: "trueOrFalseQuiz",
  YesOrNoQuiz: "yesOrNoQuiz",
  HorizontalOrVertical: "horizontalOrVertical",
  ThreeAnswersQuiz: "threeAnswersQuiz",
  IsAllowedQuiz: "isAllowedQuiz",
};