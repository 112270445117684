import React from "react";
import { useTranslation } from "react-i18next";

import {
  Container,
  TextBox,
  Icon,
  Text,
  TextBold,
} from "./listEmptyState.styled";

export const ListEmptyState = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <TextBox>
        <Icon />
        <Text>
          <TextBold>{t("idd.listEmptyState.title")}</TextBold>
          {t("idd.listEmptyState.subtitle")}
        </Text>
      </TextBox>
    </Container>
  );
};
