import React from "react";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import { useDispatch, useSelector } from "react-redux";

import { TutorialStepPopup } from "../../components/tutorialStepPopup/tutorialStepPopup.component";
import { TargetPosition } from "../../components/tutorialStepPopup/tutorialStepPopup.constants";
import { TutorialStep } from "../../components/tutorialPopup/tutorialPopup.constants";
import { selectSearchPopupActive, selectTutorialCurrentStep } from "../../redux/common/common.selectors";
import { selectUserBrands } from "../../userContext/redux/userContext.selectors";
import { CommonActions } from "../../redux/common/common.reducer";
import { BrandReset } from "../../appSettings/brandReset/brandReset.component";
import { UserRoleName } from "../../userContext/userContext.constants";
import { colors, breakpoints } from "../../UIGlobals";
import Hamburger from "./hamburger";
import { RightNav } from "./rightNav";
import { AppConfig } from "../../appConfig";
import { renderWhenTrue } from "../../helpers/rendering";

import { SearchButton, SearchIcon } from "./rightNav.styled";

const Container = styled.header`
  background: ${colors().white};
  height: 70px;
  width: 100%;
  border-bottom: 1px solid ${colors().dirtyWhite};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 25px;
  @media (${breakpoints().maxL}) {
    padding: 0 25px;
    left: 0;
    right: 0;
    position: relative;
    justify-content: center;
  }
`;

const BrandLogo = styled.div`
  width: 100px;
  height: 50px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
`;

const SearchPlaceholder = styled.div`
  width: 240px;
`;

const TutorialWrapper = styled.div`
  position: absolute;
  right: 0;
  width: 68px;
  height: 70px;
`;

const MainHeader = ({ settings, profile, menuToggle, additionalBrands, token, user }) => {
  const dispatch = useDispatch();
  const searchPopupActive = useSelector(selectSearchPopupActive);
  const tutorialCurrentStep = useSelector(selectTutorialCurrentStep);
  const brands = useSelector(selectUserBrands);
  const logo =
    settings.currentBrand
      && brands.length > 0
      ? brands.find((brand) => brand.id === settings.currentBrand)?.logo ?? null
      : additionalBrands.find((brand) => brand.id === settings.currentBrand)?.logo ?? null;
  const isOnboardingUser = user?.data?.user.role.name === UserRoleName.Onboarding;

  const toggleSearchPopup = () => {
    dispatch(CommonActions.setSearchPopupActive(!searchPopupActive));
  };

  const renderBrandLogo = renderWhenTrue(() => (
    <BrandReset>
      <BrandLogo style={{ backgroundImage: `url(${AppConfig.content}${logo})` }} />
    </BrandReset>
  ));

  return (
    <Container>
      <MediaQuery query={`(${breakpoints().maxL})`}>
        <Hamburger action={menuToggle} />
        {renderBrandLogo(!!logo)}
        {!isOnboardingUser && (
          <TutorialWrapper>
            <SearchButton onClick={toggleSearchPopup}>
              <SearchIcon active={searchPopupActive?.toString()} />
            </SearchButton>
            {tutorialCurrentStep === TutorialStep.Search && (
              <TutorialStepPopup
                topPositionInPx={100}
                rightPositionInPx={13}
                targetTopInPx={-97}
                targetRightInPx={-12}
                arrowRightInPx={2}
                targetPosition={TargetPosition.Top}
              />
            )}
          </TutorialWrapper>
        )}
      </MediaQuery>

      <MediaQuery query={`(${breakpoints().minL})`}>
        <SearchPlaceholder />
        {renderBrandLogo(!!logo)}
        <RightNav
          profile={profile}
          token={token}
          user={user}
        />
      </MediaQuery>
    </Container>
  );
};

export default MainHeader;
