import styled from "styled-components";

import { ReactComponent as TimeSvg } from "../../../../images/clock.svg";
import { Color, FontWeight } from "../../../../theme";

export const Container = styled.div`
  background-color: ${Color.white};
  padding: 20px;
`;

export const Header = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 8px;
`;

export const Thumbnail = styled.img`
  display: block;
  width: 110px;
`;

export const HeaderText = styled.div``;

export const Time = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 10px;
  font-weight: ${FontWeight.SemiBold};
  line-height: normal;
  color: ${Color.deepGray};
`;

export const TimeIcon = styled(TimeSvg)`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
`;

export const Title = styled.p`
  font-size: 12px;
  font-weight: ${FontWeight.SemiBold};
  line-height: normal;
  color: ${Color.deepGray};
  margin-top: 4px;
`;

