import React from "react";

import {
  TextContainer,
  Header,
  Text,
  TextPart,
  OrderedList,
  ListElement,
} from "../regulations.styled";

export const PrivacyPolicyContent = () => (
  <TextContainer>
    <TextPart>
      <Header>§ 1. Postanowienia ogólne</Header>
      <OrderedList>
        <ListElement>
          W związku z procesem rejestracji oraz korzystania z Serwisu Właściciele Serwisu przetwarzają dane osobowe Użytkowników. W tym zakresie Właściciele Serwisu pełnią rolę Współadministratora danych osobowych w rozumieniu ogólnego rozporządzenia o ochronie danych osobowych (RODO).
        </ListElement>
        <ListElement>
          Szczegółowe informacje dotyczące przetwarzania danych osobowych Użytkowników w związku z powyższym zostały wskazane w Klauzuli obowiązku informacyjnego umieszczonej w formularzu rejestracyjnym oraz na Platformie Szkoleniowej.
        </ListElement>
        <ListElement>
          W przypadku zaistnienia przesłanek zobowiązujących do ponownej realizacji obowiązku informacyjnego, Użytkownicy zostaną poinformowani o zmienionej treści za pośrednictwem wskazanego przez siebie adresu poczty elektronicznej. Informacja ta będzie zawierała zmienią treść lub wskazanie adresu strony, pod którym Użytkownik może zapoznać się ze zmienioną treścią.
        </ListElement>
        <ListElement>
          Niezależnie od powyższego, w ramach szkoleń wymaganych ustawą o dystrybucji ubezpieczeń Właściciele Serwisu przetwarzają dane osobowe OFWCA będących Sprzedawcami lub pracownikami MAN T&B na zlecenie Dealera (na podstawie umowy powierzenia przetwarzania danych).
        </ListElement>
        <ListElement>
          Właściciele Serwisu przetwarzają dane osobowe Użytkowników Serwisu zgodnie z obowiązującymi przepisami prawa.
        </ListElement>
        <ListElement>
          W Serwisie oprócz ww. osobowych oraz informacji związanych ze szkoleniami, mogą być zbierane niektóre informacje techniczne dotyczące Użytkownika, takie jak używany system operacyjny, typ oraz wersja przeglądarki, liczba odwiedzin serwisu oraz adresy odwiedzanych stron internetowych oraz inne informacje zbierane przez pliki cookie oraz inne podobne technologie. Informacje te mogą być klasyfikowane do danych osobowych w zależności od okoliczności.
        </ListElement>
      </OrderedList>
    </TextPart>
    <TextPart>
      <Header>§ 2. Pliki cookies postanowienia ogólne</Header>
      <OrderedList>
        <ListElement>
          Serwis wykorzystuje pliki cookies lub inne technologie związane z jego funkcjonowaniem.
        </ListElement>
        <ListElement>
          DEFINICJE<br />
          1) cookies – tzw. „ciasteczka”, tj. dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w urządzeniu końcowym Użytkownika Serwisu (np. komputerze, tablecie, smartphonie) i przeznaczone są do korzystania ze stron internetowych Serwisu. Cookies nie są szkodliwe dla Użytkowników czy też dla ich urządzeń, nie wpływają w żaden na sposób na ich działanie. Nie powodują też zmian konfiguracyjnych w urządzeniach końcowych, ani w oprogramowaniu zainstalowanym na tych urządzeniach.<br />
          2) Adres IP - unikalny numer przypisanym do komputera lub innego urządzenia komunikującego się za pośrednictwem protokołu internetowego;
        </ListElement>
      </OrderedList>
    </TextPart>
    <TextPart>
      <Header>§ 3. Rodzaje cookies</Header>
      <OrderedList>
        <ListElement>
          W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies: ”sesyjne” (session cookies) oraz ”stałe” (persistent cookies).<br />
          1) Cookies ”sesyjne” są plikami tymczasowymi, które przechowywane są w urządzeniu końcowym Użytkownika do czasu wylogowania, opuszczenia strony internetowej lub wyłączenia oprogramowania (przeglądarki internetowej).<br />
          2) Cookie „stałe”  – pliki, które pozostają na urządzeniu Użytkownika przez określony w parametrach plików cookies okres lub do momentu ich ręcznego usunięcia.
        </ListElement>
        <ListElement>
          Poza własnymi plikami cookies (sesyjne cookies), są wykorzystywane tzw. third party cookies (ang. ciasteczka stron trzecich). Wykorzystywane są one do zapewniania na stronie internetowej, lub za pośrednictwem strony internetowej właściwości lub funkcji pochodzących od stron trzecich, usługi analityki strony internetowej).
        </ListElement>
        <ListElement>
          Pliki cookies przechowywane są w urządzeniu końcowym Użytkownika przez czas określony w&nbsp;parametrach plików cookies (cookies mogą pozostawać na urządzeniu Użytkownika w różnym czasie – może to być czas trwania sesji, albo ustawiony czas istnienia), lub do czasu ich usunięcia przez Użytkownika.
        </ListElement>
      </OrderedList>
    </TextPart>
    <TextPart>
      <Header>§ 4. Zakres zbieranych danych</Header>
      <OrderedList>
        <ListElement>
          Cookies identyfikują dane komputera i przeglądarki używane do przeglądania stron internetowych – pozwalają np. dowiedzieć się, czy dany komputer już odwiedzał stronę. Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.
        </ListElement>
        <ListElement>
          W ramach Serwisu mogą być przetwarzane dane dotyczące sposobu korzystania z witryny za pomocą komputera, tabletu czy smartfona lub innego urządzenia, do którego Użytkownik uzyskuje dostęp do stron internetowych.
        </ListElement>
        <ListElement>
          Za pomocą stosowanych technologii internetowych przetwarzane mogą być takie dane jak:<br />
          1) dane urządzenia, takie jak: model sprzętu, unikalne identyfikatory urządzeń, adres MAC, adres IP, wersja systemu operacyjnego i ustawienia urządzenia;<br />
          2) dziennik: czas korzystania z Serwisu, dane wyszukiwania oraz wszelkie informacje przechowywane w plikach cookie, które jednoznacznie identyfikują przeglądarkę lub konto Użytkownika;<br />
          3) dane dotyczące lokalizacji: dane o lokalizacji Użytkownika uzyskane za pomocą różnych technologii pozycjonowania, takich jak GPS, punkty dostępu Wi-Fi lub inne czujniki, które mogą dostarczać dane z pobliża urządzenia (po wyrażeniu zgody na lokalizację);<br />
          4) inne dane, np.: dane na temat korzystania z Serwisu.
        </ListElement>
      </OrderedList>
    </TextPart>
    <TextPart>
      <Header>§ 5. Podstawa prawna, cel stosowania nowych technologii, ustawienia</Header>
      <OrderedList>
        <ListElement>
          Przetwarzanie danych osobowych pochodzących z plików cookies lub podobnych technologii na stronie naszego Serwisu w celach zapewnienia poprawnego i bezpiecznego funkcjonowania Serwisu, odbywa się na podstawie naszego uzasadnionego interesu. Przykładem są uwierzytelniające pliki cookies wykorzystywane do usług wymagających uwierzytelniania w ramach Serwisu; służące do zapewnienia bezpieczeństwa, np. wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania w ramach Serwisu.
        </ListElement>
        <ListElement>
          Możemy też przetwarzać Twoje dane, w tym dane osobowe pochodzące z plików cookies lub podobnych technologii w celach analitycznych oraz funkcjonalnych. Podstawą prawną przetwarzania danych, w tym danych osobowych jest wówczas wyraźna zgoda Użytkownika Serwisu. Pliki cookie:<br />
          1) analityczne - Pliki cookie umożliwiające zbieranie informacji o sposobie korzystania ze stron internetowych, aby pomóc nam zrozumieć, w jaki sposób wykorzystywana jest nasza strona internetowa lub pomóc nam w jej dostosowaniu. Te pliki cookie pozwalają nam na zliczanie wizyt i źródeł ruchu w Serwisie, dzięki czemu możemy mierzyć i usprawniać jego działanie. Dzięki nim możemy dowiedzieć się, jak odwiedzający poruszają się po naszej stronie internetowej. Wszystkie dane gromadzone przez te pliki cookie są agregowane, w związku z czym pozostają anonimowe. Jeśli Użytkownik nie wyrazi zgody na używanie tych plików cookie, nie będziemy wiedzieć, kiedy odwiedził naszą stronę internetową, ani nie będziemy w stanie monitorować jej działania. Bez tych plików cookie niektóre funkcje mogą stać się niedostępne.<br />
          2) funkcjonalne - Pliki cookie umożliwiające „zapamiętanie” wybranych przez Użytkownika ustawień i personalizację interfejsu Użytkownika, np. w zakresie wybranego języka lub regionu, z którego pochodzi Użytkownik, rozmiaru czcionki, wyglądu strony internetowej itp. Zadaniem tych plików cookie jest umożliwienie użytkownikowi korzystania z naszej witryny w sposób lepiej dostosowany do jego osobistych preferencji w oparciu o jego wcześniejsze wizyty i wybrane ustawienia. Jeśli użytkownik nie wyrazi zgody na używanie tych plików cookie, niektóre funkcje strony mogą nie działać prawidłowo.
        </ListElement>
        <ListElement>
          Użytkownicy Serwisu mogą wyrazić zgodę na wykorzystywanie jedynie poszczególnych typów plików cookie, a także zmienić już wyrażone zgody na wykorzystanie poszczególnych typów plików cookie korzystając z dedykowanego do tego celu Panelu do zarządzania plikami cookie (wejście do Panelu następuje z poziomu przycisku „ZMIEŃ USTAWIENIA” przy wejściu na stronę internetową po raz pierwszy lub przy każdorazowym następnym korzystaniu ze strony).
        </ListElement>
        <ListElement>
          W każdym czasie Użytkownik Serwisu może także cofnąć wyrażoną zgodę na wykorzystanie plików cookie poprzez usunięcie plików cookie ze swojej przeglądarki internetowej. W tym zakresie:<br />
          1) Pliki cookies mogą być przez Użytkownika usunięte po ich zapisaniu przez Administratora, poprzez odpowiednie funkcje przeglądarki internetowej lub inne narzędzia służące do tego celu.<br />
          2) Ustawienia te mogą zostać zmienione w szczególności w taki sposób, aby blokować automatyczną obsługę plików cookies w ustawieniach przeglądarki internetowej, bądź informować o ich każdorazowym zamieszczeniu w urządzeniu Użytkownika Serwisu. Szczegółowe informacje o możliwości i sposobach obsługi plików cookies dostępne są w ustawieniach oprogramowania (przeglądarki internetowej).<br />
          3) W wyniku zmiany ustawień w przeglądarce Użytkownika zostanie umieszczony tzw. plik cookie opt-out. Służy on wyłącznie do identyfikacji sprzeciwu Użytkownika – braku zgody. Należy pamiętać, iż plik cookie opt-out działa tylko w przeglądarce, przy użyciu której został zapisany. Jeśli Użytkownik usunie wszystkie cookies, lub będzie używać innej przeglądarki bądź innego urządzenia końcowego, to ustawienia opt-out trzeba dokonać ponownie.<br />
          4) W ramach systemów dostępnych jest wiele różnych przeglądarek internetowych. Szczegółowe informacje na temat ustawień dotyczących plików cookies można uzyskać w&nbsp;ustawieniach w menu używanej przeglądarki. Informacje o tym, jak zmienić ustawienia swojej przeglądarki Użytkownik znajdzie w opisie funkcji pomocy przeglądarki, lub może skontaktować się z producentem oprogramowania odnośnie instrukcji dotyczących wyłączania i usuwania plików cookies.<br />
          5) Informacje dotyczące konfiguracji ustawień stanowią Załącznik nr 2 do Polityki prywatności oraz wykorzystywania plików cookies.
        </ListElement>
        <ListElement>
          Należy pamiętać, że niektóre pliki cookies są niezbędne do poprawnego funkcjonowania naszych stron internetowych, a ich usunięcie lub wyłączenie może obniżyć ich funkcjonalność.
        </ListElement>
        <ListElement>
          Cookies lub inne technologie wykorzystywane są automatycznie. Nie oznacza to jednak wykorzystywane ich z zamiarem automatycznego podejmowania decyzji lub w celu wywołania podobnego skutku prawnego, mogącego godzić w interesy lub prywatność Użytkowników.
        </ListElement>
      </OrderedList>
    </TextPart>
    <TextPart>
      <Header>§ 6. Funkcjonalności, technologie partnerów zewnętrznych</Header>
      <OrderedList>
        <ListElement>
          W Serwisie korzystamy z opisanych poniżej narzędzi podmiotów trzecich związanych z analityką Google Analytics.
        </ListElement>
        <ListElement>
          Korzystamy z narzędzi Google Analytics, które zbierają anonimowe informacje na temat odwiedzin Użytkownika w Serwisie, takie jak podstrony, które wyświetlił, czas, jaki spędził w serwisie czy przejścia pomiędzy poszczególnymi stronami. Informacje te są zbierane w celu generowania anonimowych statystyk dotyczących funkcjonowania stron oraz umożliwienia analizy aktywności na stronie internetowej tak, aby jak najlepiej dopasować naszą ofertę do potrzeb Użytkowników. W tym celu wykorzystywane są pliki cookies firmy Google LLC dotyczące usługi Google Analytics.
        </ListElement>
        <ListElement>
          W serwisie mogą znajdować się także zewnętrzne linki. Przy korzystaniu z tych linków Użytkownik opuszcza stronę internetową.
        </ListElement>
        <ListElement>
          VWFS nie ma kontroli nad tym, jakie dane gromadzi dostawca wtyczek lub stron serwisów społecznościowych i jak je przetwarza. Aby uzyskać informacje na temat celu i zakresu gromadzenia danych, w tym także plików cookies tam stosowanych, ich dalszego przetwarzania i&nbsp;wykorzystywania przez zewnętrznych dostawców oraz przysługujących Użytkownikom praw oraz opcji ustawień ochrony prywatności tych dostawców, Użytkownik może zapoznać się z informacją o ochronie danych odpowiedniego dostawcy.
        </ListElement>
        <ListElement>
          W związku z możliwością udostępniania danych zbieranych przy pomocy cookies pochodzących od naszych dostawców zewnętrznych (wykaz dostawców zewnętrznych stanowi Załącznik nr 1 do Polityki Plików Cookies), informujemy, iż niektóre dane mogą być przekazane poza teren Europejskiego Obszaru Gospodarczego
        </ListElement>
      </OrderedList>
    </TextPart>
    <TextPart>
      <Header>§ 7. Postanowienia końcowe</Header>
      <OrderedList>
        <ListElement>
          Więcej informacji na temat plików cookies i innych technologii śledzenia, w tym tego, jak je wyłączyć, znajduje się na stronie http://wszystkoociasteczkach.pl/
        </ListElement>
        <ListElement>
          Użytkownik może wyłączyć poszczególnych reklamodawców korzystających z historii wyszukiwania w celu dostarczania internetowych reklam behawioralnych, odwiedzając stronę<br />
          http://www.youronlinechoices.com/pl/twojewybory
        </ListElement>
        <ListElement>
          Właściciel Serwisu informuje, że ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności dostępne na stronach internetowych Serwisu.
        </ListElement>
        <ListElement>
          Na stronach niniejszego serwisu mogą zostać umieszczone linki umożliwiające użytkownikom bezpośrednie przejścia na inne strony internetowe. Volkswagen Bank GmbH Sp. o.o. Oddział w Polsce, Volkswagen Financial Services Polska Sp. z o.o. oraz Volkswagen Serwis Ubezpieczeniowy Sp. z.o.o. nie odpowiada jednak za politykę ochrony prywatności stosowaną przez właścicieli tych stron, ani za treść informacji tam zawartych.
        </ListElement>
        <ListElement>
          Bez względu na metodę przekazywania danych osobowych zawsze istnieje ryzyko przechwycenia ich przez osoby postronne. Volkswagen Bank GmbH Sp. o.o. Oddział w Polsce, Volkswagen Financial Services Polska Sp. z o.o. oraz Volkswagen Serwis Ubezpieczeniowy Sp. z o.o. podjęły odpowiednie kroki w celu zminimalizowania ryzyka nieautoryzowanego dostępu do zebranych informacji osobowych, ich niewłaściwego użycia i wyeliminowania wszelkich niedokładności związanych z procesem zbierania tychże danych.<br />
          1) Powyższe zasady Polityki Prywatności mogą ulec zmianie w dowolnej chwili. Volkswagen Bank GmbH Sp.z.o.o. Oddział w Polsce., Volkswagen Financial Services Polska Sp. z o.o.  oraz Volkswagen Serwis Ubezpieczeniowy Sp. z o.o. może zmieniać, modyfikować lub wycofywać zawartość stron oraz dostęp do tego serwisu bez uprzedzenia.<br />
          2) Wszelkie pojęcia pisane dużą literą lub zdefiniowane wprost mają znaczenie nadane im  w&nbsp;niniejszym dokumencie „Zasady korzystania z Platformy Szkoleniowej”.<br />
          3) Załączniki stanowią integralną część Polityki prywatności i wykorzystywania plików cookies.
        </ListElement>
      </OrderedList>
    </TextPart>
    <TextPart>
      <Header>§ 8. Wykaz Załączników</Header>
      <Text>
        Załącznik nr 1 - Wykaz dostawców zewnętrznych<br />
        Załącznik nr 2 - Informacje dotyczące konfiguracji ustawień
      </Text>
    </TextPart>
    <TextPart>
      <Header>
        Załącznik nr 1 do Polityki prywatności i plików cookies<br />
        Wykaz dostawców zewnętrznych
      </Header>
      <Text>
        Google LLC (także dla YouTube), 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA<br />
        https://policies.google.com/privacy?hl=pl
      </Text>
    </TextPart>
    <TextPart>
      <Header>
        Załącznik nr 2 do Polityki prywatności i plików cookies<br />
        Informacje dotyczące konfiguracji ustawień
      </Header>
      <OrderedList>
        <ListElement>
          Instrukcje w jaki sposób dokonać konfiguracji ustawień plików cookie w przykładowych przeglądarkach znajdują się pod następującymi linkami:<br />
          1) w przeglądarce Internet Explorer™<br />
          https://support.microsoft.com/pl-pl/help/278835/how-to-deletecookie-files-in-internet-explorer<br />
          2) w przeglądarce Mozilla Firefox™<br />
          https://support.mozilla.org/pl/kb/usuwanie-ciasteczek<br />
          3) w przeglądarce Chrome™<br />
          https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=pl<br />
          4) w przeglądarce Opera™<br />
          https://www.opera.com/help/tutorials/security/cookies/<br />
          5) w przeglądarce Safari™<br />
          https://support.apple.com/pl-pl/HT201265
        </ListElement>
        <ListElement>
          Informacje, w jaki sposób dokonać konfiguracji ustawień plików cookie w zakresie urządzeń mobilnych, znajdują się na stronach internetowych producentów najpopularniejszych systemów mobilnych (urządzenia z systemem iOS, Android, Windows Phone, BlackBerry).
        </ListElement>
        <ListElement>
          Użytkownik może także zmienić ustawienia dot. poszczególnych cookies stosowanych przez naszych zewnętrznych dostawców:<br />
          Google Opt-out<br />
          Blokowanie Google Analytics – Użytkownik może odwiedzić stronę<br />
          https://tools.google.com/dlpage/gaoptout?hl=pl.
        </ListElement>
      </OrderedList>
    </TextPart>
  </TextContainer>
);
