import { createActions, createReducer } from "reduxsauce";

export const { Types: IddTypes, Creators: IddActions } = createActions(
  {
    getInitialData: null,
    getInitialDataSuccess: ["data"],
    getContents: null,
    getContentsSuccess: ["contents"],
    getPassHistory: ["year", "page"],
    getPassHistorySuccess: ["passHistory"],
    getCertificatesHistory: ["page"],
    getCertificatesHistorySuccess: ["certificatesHistory"],
  },
  { prefix: "IDD/" }
);

const INITIAL_STATE = {
  insuranceCompanies: null,
  knfHistories: null,
  stats: null,
  certificateInfo: null,
  contents: null,
  passHistory: null,
  certificatesHistory: null,
};

const getInitialDataSuccess = (state, { data }) => {
  const { knfHistories, stats, insurances, certificateInfo } = data;

  return {
    ...state,
    knfHistories,
    stats,
    insurances,
    certificateInfo,
  };
};

const getContentsSuccess = (state, { contents }) => {
  return { ...state, contents };
};

const getPassHistorySuccess = (state, { passHistory }) => {
  return {
    ...state,
    passHistory,
  };
};

const getCertificatesHistorySuccess = (state, { certificatesHistory }) => {
  return {
    ...state,
    certificatesHistory,
  };
};

export const reducer = createReducer(INITIAL_STATE, {
  [IddTypes.GET_INITIAL_DATA_SUCCESS]: getInitialDataSuccess,
  [IddTypes.GET_CONTENTS_SUCCESS]: getContentsSuccess,
  [IddTypes.GET_PASS_HISTORY_SUCCESS]: getPassHistorySuccess,
  [IddTypes.GET_CERTIFICATES_HISTORY_SUCCESS]: getCertificatesHistorySuccess,
});
