import styled from "styled-components";

import { breakpoints, colors } from "../../../../UIGlobals";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 150px 100px 134px 130px;
  @media (${breakpoints().maxL}) {
    padding: 60px;
    flex-direction: column;
  }
  @media (${breakpoints().maxM}) {
    padding: 60px 0 0;
  }
`;

export const ThumbsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 40px;
  & > div:first-child {
    margin-right: 15px;
  }
  @media (${breakpoints().maxL}) {
    margin: 40px 0 0;
    justify-content: center;
  }
`;

export const ThumbButton = styled.div`
  min-width: 120px;
  height: 120px;
  border: 1px solid ${colors().gray11};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 40px;
  background-color: ${props =>
  props.wrongTheme
    ? colors().purple
    : props.correctTheme
      ? colors().blue2
      : "transparent"
  };
  @media (${breakpoints().maxL}) {
    width: 133px;
    height: 133px;
  }
`;

export const Text = styled.span`
  font-size: 20px;
  line-height: 33px;
`;

export const WrongAnswerComment = styled.div`
  font-size: 16px;
  margin-top: 16px;
  line-height: 22px;
  color: ${colors().purple};
  @media (${breakpoints().maxL}) {
    margin-top: 40px;
    height: 0;
    text-align: center;
  }
`;

export const CorrectAnswerComment = styled(WrongAnswerComment)`
  color: ${colors().blue2};
`;
