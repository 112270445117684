import styled, { css } from "styled-components";
import { prop } from "ramda";


import { styleWhenTrue } from "../../../../helpers/rendering";
import {
  Color,
  ContentMaxWidth,
  FontWeight,
  Media,
  Separator,
} from "../../../../theme";
import CarImg from "../../images/carOnRoad.jpg";

export const Container = styled.div`
  padding: 20px 0 40px;

  ${Media.tabletWide`
    padding: 40px 0;
  `}
`;

export const Wrapper = styled.div`
  max-width: calc(${ContentMaxWidth} + 40px);
  padding: 0 20px;
  margin: 0 auto;

  ${Media.tabletWide`
    display: flex;
    min-height: 630px;
  `}
`;

export const Image = styled.div`
  width: 100%;
  aspect-ratio: 335 / 285;
  flex-shrink: 0;
  background-image: url(${CarImg});
  background-size: cover;
  background-position: center;
  max-height: 360px;

  ${Media.tabletWide`
    width: 50%;
    max-height: unset;
    background-position: left center;
  `}
`;

export const Content = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  
  ${Media.tabletWide`
    width: 50%;
    padding: 40px 0 40px 10%;
    margin-top: 0;
  `}
`;

export const Title = styled.p`
  color: ${Color.deepGray};
  font-size: 18px;
  line-height: normal;
  font-weight: ${FontWeight.Bold};
`;

export const List = styled.div``;

export const Item = styled.div`
  border-top: 1px solid ${Color.black};
  padding-bottom: 28px;

  &:last-of-type {
    border-bottom: 1px solid ${Color.black};
  }
`;

export const ItemHeader = styled.div`
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ItemNumber = styled.p`
  color: ${Color.deepGray};
  font-size: 15px;
  line-height: normal;
  font-weight: ${FontWeight.SemiBold};
`;

export const Activity = styled.div`
  padding: 4px 12px 4px 16px;
  border-radius: 100px;
  background-color: ${Color.almostWhite};
  color: ${Color.gray9};
  line-height: 1.6;
  font-weight: ${FontWeight.SemiBold};
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  ${styleWhenTrue(prop("$isActive"), css`
    background-color: ${() => Color.brandBasic};
    color: ${Color.white};
  `)}
`;

export const Dot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: 1px solid ${Color.gray9};

  ${styleWhenTrue(prop("$isActive"), css`
    border: 1px solid ${Color.white};
  `)}
`;

export const StyledSeparator = styled(Separator)`
  margin: 0;
  margin-bottom: 20px;
`;

export const Prop = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  & + & {
    margin-top: 10px;
  }
`;

export const PropTitle = styled.p`
  color: ${Color.gray9};
  font-size: 12px;
  line-height: normal;
  width: 120px;
`;

export const PropValue = styled.p`
  color: ${Color.gray6};
  font-weight: ${FontWeight.SemiBold};
  line-height: 1.3;
`;
