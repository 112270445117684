import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { selectUserData } from "../../../../userContext/redux/userContext.selectors";
import { ButtonColor } from "../../../../components/button/button.constants";
import { simpleDateFromTimestamp } from "../../../../helpers/date";
import { AppConfig } from "../../../../appConfig";
import { ListDesktopDownloadButton, ListDesktopDownloadIcon } from "../filesToDownload/filesToDownload.styled";

import {
  Container,
  Cell,
  Icon,
  Title,
} from "./certificateItemDesktop.styled";

export const CertificateItemDesktop = ({ id, passed, validityTimestamp }) => {
  const { t } = useTranslation();
  const user = useSelector(selectUserData);
  const date = simpleDateFromTimestamp(validityTimestamp);
  const year = date.slice(6);

  return (
    <Container>
      <Cell>
        <Icon />
        <Title>{t("idd.certificateItemDesktop.year", { year })}</Title>
      </Cell>
      <Cell $withError={!passed}>
        {passed ? t("idd.certificateItemDesktop.passed") : t("idd.certificateItemDesktop.notPassed")}
      </Cell>
      <Cell>{date}</Cell>
      <Cell>
        <ListDesktopDownloadButton
          color={ButtonColor.Primary}
          disabled={!passed}
          onClick={() => window.open(`${AppConfig.api}/download/certificate/${id}?X-AUTH-TOKEN=${user.token}`)}
        >
          {t("idd.certificateItemDesktop.download")}
          <ListDesktopDownloadIcon />
        </ListDesktopDownloadButton>
      </Cell>
    </Container>
  );
};
