import { ItemType, UriType } from "./passHistory.constants";

export const getUriType = (type) => {
  switch (type) {
  case ItemType.Content: return UriType.Content;
  case ItemType.Event: return UriType.Event;
  case ItemType.External: return UriType.External;
  case ItemType.Individual: return UriType.Individual;
  }
};
