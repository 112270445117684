import { handleApiError, phpApi } from "../../../api";

export const getInitialData = () => {
  return phpApi.get("/api/user/idd/dashboard").catch(handleApiError);
};

export const getContents = () => {
  return phpApi.get("/api/user/idd/contents").catch(handleApiError);
};

export const getPassHistory = (year, page) => {
  return phpApi.get("/api/user/idd/minutes", {
    params: { limit: 4, year, page },
  }).catch(handleApiError);
};

export const getCertificatesHistory = (page) => {
  return phpApi.get("/api/user/idd/certificates", {
    params: { limit: 4, page },
  }).catch(handleApiError);
};
