import styled from "styled-components";

import { breakpoints, colors } from "../../../../UIGlobals";

export const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 33px;
  margin-bottom: 10px;
  color: ${colors().semiDarkGray};
  text-align: left;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 115px 65px 175px;
  @media (${breakpoints().maxL}) {
    padding: 115px;
  }
  @media (${breakpoints().maxM}) {
    padding: 60px 0;
  }
  @media (${breakpoints().maxS}) {
    padding: 60px 0 0;
  }
`;

export const AnswerBoxTitle = styled.div`
  font-size: 20px;
  color: ${props => (
  props.greyed
    ? colors().gray10
    : props.blue
      ? colors().blue2
      : colors().semiDarkGray)};
  ${props => props.bold && "font-weight: bold"};
`;

export const AnswerBoxesContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  @media (${breakpoints().maxM}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const AnswerBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0 30px;
  width: 420px;
  height: 100px;
  border: 1px solid ${props => (
  props.wrongTheme
    ? colors().purple
    : props.greyed
      ? "rgba(122, 134, 150, 0.3)"
      : colors().blue3)};
  & > div {
    padding-left: 30px;
  }
  @media (${breakpoints().maxL}) {
    width: 340px;
  }
  @media (${breakpoints().minM}) {
    &:first-child {
      margin-right: 10px;
    }
  }
  @media (${breakpoints().maxM}) {
    width: 280px;
    &:last-child {
      margin-top: 5px;
    }
  }
`;
